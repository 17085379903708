<template>
    <div class="fengtaiAppfooter">
      <div class="youqinglink">
        <div class="bottomSelect">
          <div class="title">友情链接：</div>
            <p class="linIm" v-for="(items, indexs) in linkYQLJ" :key="indexs" @click="toPage(items)">
                {{ items.navigationName }}</p>
        </div>
      </div>
      <div class="footWebdongzhi">
        <div v-html="webInfor&&webInfor.WEB_FOOTER_INFORMSTION" style="width: 1100px; margin: 0px auto"></div>
      </div>
    </div>
  </template>

<script>
import home from '@/api/index.js'
import { jumpTopage } from '@/utils/toDetail'
export default {
  data() {
    return {
      linkYQLJ: [], // 友情链接
      singArry: 'YQLJ'
    }
  },
  created() {},
  computed: {
    webInfor() {
      return this.$store.state.$webInfor
    },
    configWeb() {
      return this.$store.state.$config
    }
  },
  mounted() {
    this.getYouqinglink(this.singArry)
  },
  methods: {
    toPage(item) {
      console.log(item, '>>%%%出文明')
      jumpTopage(item)
    },
    getYouqinglink(sign) {
      home
        .getAppOperationGetNavListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then(async (res) => {
          if (res.data && res.success) {
            this.linkYQLJ = res.data[0].info
          }
        })
    }
  }
}
</script>

    <style  lang="scss" scoped>
    .fengtaiAppfooter{
        background: #F5F5F5;
    }
  .youqinglink {
    width:$primaryWidth;
    margin:10px auto;
    border-bottom: 2px solid #E5E5E5;
    padding:22px 22px;
    box-sizing: border-box;

     .bottomSelect{
        display: flex;
     }
    .title{
        color: #2A2A2A;
        font-weight:bold;
        font-size:18px
    }
    .linIm{
        color:#333333;
        font-size:17px;
        padding:0 20px;
        cursor: pointer;
    }
    .linIm:hover{
        color:$primaryHoverColor;
    }

  }
  .footWebdongzhi {
    width: $primaryWidth;
    overflow: hidden;
    padding: 18px 0px 28px;
    margin: 0px auto;
    text-align: center;
    color:#868686;
    font-size:14px;
    a{
        color:#868686;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  </style>
