<template>
    <div
      class="headWeb"
      :class="{ headwebCn: naveType == 'indexBack' && backimgbox }"
    >
      <!-- 这里是飘红头首页不需要传值默认indexBack -->
      <div
      class="backboxzhe"
      v-if="naveType == 'indexBack' && backimgbox"
      @click="bannerToOther(this.backimgbox)"
    ></div>
    <div class="backbox" v-if="naveType == 'indexBack' && backimgbox">
      <img :src="backimgbox.advertisementImage | uploadFilters" alt />
    </div>

      <div class="head"  :class="{ headCn: naveType == 'indexBack' && backimgbox }"  >
        <div class="headNotice">
            <div class="notice">
                中共宣州区委宣传部 宣州区文明办 主办
            </div>
        </div>
        <div class="homeLogo">
          <!-- 根据广告图表示获取logo图片 -->
          <div class="zhanweiOne">
            <img
              class="logo"
              v-if="logo.length"
              :src="logo[0].advertisementImage | uploadFilters"
              alt
            />
            <div class="time-tianqi">
                <p class="weath" v-if="weatherInfo" @click="goToWeather">
                  <span>{{ weatherInfo.city }}</span>
                  <img :src="weatherImage" alt="" />
                  <span style="margin-right: 10px;">{{ weatherInfo.weather }}</span>
                  <span>{{ weatherInfo.temperature }}℃</span>
                  <i class="el-icon-arrow-down" style="font-weight: bold; margin-left: 10px;"></i>
                </p>
              </div>
          </div>
          <!-- 头部右边图 -->
          <div class="mydefaultImage">
            <img
              class="gif"
              v-for="(item, index) in bannerL"
              :key="index"
              :class="index === chosedIndex ? 'blockContent' : ''"
              :src="item.advertisementImage | uploadFilters"
              alt
            />
          </div>
        </div>
        <div class="commHeader">
          <!--导航-->
          <div class="nav">
            <ul class="commHeaderTextDaohang">
              <li
                class="commHeaderText"
                v-for="(item, index) in myallTitles[0].info"
                :key="index"
                :class="
                  activecolumn === item.columnSign
                    ? 'actvie'
                    : '' || activecolumn === item.navigationSign
                    ? 'actvie'
                    : ''
                "
                @click="toPage(item)"
              >
                {{ item.navigationName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import home from '@/api/index.js'
import props from './props.js'
import axios from 'axios'
import { adverTopage, jumpTopage } from '@/utils/toDetail'
export default {
  mixins: [props],
  components: {},
  data() {
    return {
      chosedIndex: 0,
      backimgbox: '',
      bannerL: [],
      myallTitles: [{ info: [] }],
      logo: [],
      activecolumn: '',
      weatherImage: require('@/assets/tianqi/sun.png'),
      weatherInfo: null
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    },
    loginStatus() {
      const staus = this.$store.state.token && this.$store.state.userInfo
      return staus
    }
  },
  created() {
    this.adverDataLink('WZLOGO', 'logo')
    this.mynav() // 这里是我的导航
    if (this.naveType === 'indexBack') {
      if (this.adver) {
        if (this.redsign) {
          this.adverDataLink(this.redsign, 'pc')
        }
      } else {
        this.bigBckg() // 网站飘红
      }
    }
    if (this.naveType !== 'noBanner') {
      if (this.adver) {
        if (this.bannersign) {
          this.adverDataLink(this.bannersign, 'banner') // 广告查图片
        }
      }
    }
    if (this.$route.query.sing) {
      this.activecolumn = this.$route.query.sing
    } else {
      this.activecolumn = this.$route.query.column
    }
    this.getWeather()
  },
  methods: {
    indexWidth() {
      this.$emit('indexWidth')
    },
    goToWeather() {
      window.open('https://tianqi.2345.com/')
    },
    navToHome() {
      const { href } = this.$router.resolve({
        path: '/'
      })
      window.open(href)
    },
    adverDataLink(sign, name) {
      home
        .getAppOperationGetAdvListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (name === 'pc') {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            } else if (name === 'logo') {
              console.log(sign, '>EEE')
              this.logo = res.data // logo
            } else {
              this.bannerL = res.data
              setInterval(() => {
                if (this.chosedIndex === this.bannerL.length - 1) {
                  this.chosedIndex = 0
                } else {
                  this.chosedIndex++
                }
              }, 2000)
            }
          }
        })
    },
    // 获取天气
    getWeather() {
      axios
        .get('https://restapi.amap.com/v3/ip', {
          params: {
            key: this.$store.state.$config.headWeb.weatherKey
          }
        })
        .then((ret) => {
          console.log(ret.data, '获取天气', this.$store.state.$config.headWeb.weatherKey)
          if (ret.data.status === '1') {
            axios
              .get('https://restapi.amap.com/v3/weather/weatherInfo', {
                params: {
                  city: ret.data.adcode,
                  key: this.$store.state.$config.headWeb.weatherKey
                }
              })
              .then((res) => {
                console.log(res.data)
                if (res.data.status === '1') {
                  this.weatherInfo = res.data.lives[0]
                  if (res.data.lives[0].weather === '晴') {
                    this.weatherImage = require('@/assets/tianqi/sun.png')
                  } else if (
                    res.data.lives[0].weather === '少云' ||
                    res.data.lives[0].weather === '晴间多云' ||
                    res.data.lives[0].weather === '多云'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/duoyun.png')
                  } else if (res.data.lives[0].weather === '暴雪') {
                    this.weatherImage = require('@/assets/tianqi/baoxue.png')
                  } else if (
                    res.data.lives[0].weather === '暴雨' ||
                    res.data.lives[0].weather === '大暴雨' ||
                    res.data.lives[0].weather === '特大暴雨' ||
                    res.data.lives[0].weather === '强阵雨' ||
                    res.data.lives[0].weather === '暴雨-大暴雨' ||
                    res.data.lives[0].weather === '大暴雨-特大暴雨' ||
                    res.data.lives[0].weather === '极端降雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/baoyu.png')
                  } else if (
                    res.data.lives[0].weather === '大雪' ||
                    res.data.lives[0].weather === '大雪-暴雪' ||
                    res.data.lives[0].weather === '阵雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/daxue.png')
                  } else if (
                    res.data.lives[0].weather === '大雨' ||
                    res.data.lives[0].weather === '大雨-暴雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/dayu.png')
                  } else if (
                    res.data.lives[0].weather === '阵雨' ||
                    res.data.lives[0].weather === '雷阵雨' ||
                    res.data.lives[0].weather === '雷阵雨并伴有冰雹' ||
                    res.data.lives[0].weather === '强雷阵雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/leizhengyu.png')
                  } else if (
                    res.data.lives[0].weather === '霾' ||
                    res.data.lives[0].weather === '中度霾' ||
                    res.data.lives[0].weather === '重度霾' ||
                    res.data.lives[0].weather === '严重霾' ||
                    res.data.lives[0].weather === '雾' ||
                    res.data.lives[0].weather === '浓雾' ||
                    res.data.lives[0].weather === '强浓雾' ||
                    res.data.lives[0].weather === '轻雾' ||
                    res.data.lives[0].weather === '大雾' ||
                    res.data.lives[0].weather === '特强浓雾'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/wumai.png')
                  } else if (
                    res.data.lives[0].weather === '小雪' ||
                    res.data.lives[0].weather === '雪' ||
                    res.data.lives[0].weather === '小雪-中雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/xiaoxue.png')
                  } else if (
                    res.data.lives[0].weather === '雨' ||
                    res.data.lives[0].weather === '小雨' ||
                    res.data.lives[0].weather === '小雨-中雨' ||
                    res.data.lives[0].weather === '冻雨' ||
                    res.data.lives[0].weather === '毛毛雨/细雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/xiaoyu.png')
                  } else if (res.data.lives[0].weather === '阴') {
                    this.weatherImage = require('@/assets/tianqi/ying.png')
                  } else if (
                    res.data.lives[0].weather === '雨夹雪' ||
                    res.data.lives[0].weather === '雨雪天气' ||
                    res.data.lives[0].weather === '阵雨夹雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/yujiaxue.png')
                  } else if (
                    res.data.lives[0].weather === '中雪' ||
                    res.data.lives[0].weather === '中雪-大雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/zhongxue.png')
                  } else if (
                    res.data.lives[0].weather === '中雨' ||
                    res.data.lives[0].weather === '中雨-大雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/zhongyu.png')
                  } else if (
                    res.data.lives[0].weather === '有风' ||
                    res.data.lives[0].weather === '微风' ||
                    res.data.lives[0].weather === '平静' ||
                    res.data.lives[0].weather === '和风' ||
                    res.data.lives[0].weather === '清风' ||
                    res.data.lives[0].weather === '强风/劲风' ||
                    res.data.lives[0].weather === '疾风' ||
                    res.data.lives[0].weather === '大风' ||
                    res.data.lives[0].weather === '烈风' ||
                    res.data.lives[0].weather === '风暴' ||
                    res.data.lives[0].weather === '狂爆风' ||
                    res.data.lives[0].weather === '飓风' ||
                    res.data.lives[0].weather === '热带风暴' ||
                    res.data.lives[0].weather === '龙卷风' ||
                    res.data.lives[0].weather === '浮尘' ||
                    res.data.lives[0].weather === '扬沙' ||
                    res.data.lives[0].weather === '沙尘暴' ||
                    res.data.lives[0].weather === '强沙尘暴'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/feng.png')
                  } else {
                    this.weatherImage = require('@/assets/tianqi/weizhi.png')
                  }
                }
              })
          }
        })
    },
    // 广告图跳转
    bannerToOther(item) {
      adverTopage(item)
    },
    toPage(item) {
      console.log(item, '>>%%%出文明')
      jumpTopage(item)
    },
    mynav() {
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: this.sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success) {
            this.myallTitles = res.data
          }
        })
    },
    // 大的飘红接口 不要删每个网站必要的
    bigBckg() {
      if (this.redsign) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.redsign,
            appName: this.configWeb.appName
          })
          .then((res) => {
            console.log('res飘红头', res)
            if (res.success && res.data && res.data.length) {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            }
          })
      }
    },

    // 回首页
    goBackIndex() {
      this.$router.push({ path: '/' })
    }

  }
}
</script>

 <style lang="scss" scoped>
  .head {
    position: relative;
    background: #fff;
  }
  .headCn{
    width: $primaryWidth;
    margin: 0 auto;
  }
  //导航logo
  .homeLogo {
    height: 136px;
    width: $primaryWidth;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    .zhanweiOne {
      width: 297px;
      height: 92px;
      position: relative;
      img {
        width: 100%;
      }
    }
    .mydefaultImage {
      position: absolute;
      width: auto;
      height: 83px;
      max-width: 892px;
      right: 0;
      img {
        width: auto;
        height: 100%;
      }
    }
  }
  //导航文字
  .commHeader {
    height: 53px;
    background:url(@/assets/xuanzhouwm/navBg.png) no-repeat left center;
    min-width: $primaryWidth;
    width: $primaryWidth;
    margin:0 auto;

    .nav {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      width: $primaryWidth;
    }
    .el-button + .el-button {
      margin-left: 0 !important;
    }
    .commHeaderTextDaohang {
      box-sizing: border-box;
      width: $primaryWidth;
      height: 53px;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      .commHeaderText {
        list-style: none;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        padding: 0 30px;
        line-height: 53px;
        &:hover {
          color: #fff;
          background:url(@/assets/xuanzhouwm/activLi.png) no-repeat left center;
        }
      }
      .commHeaderText :hover,
      .actvie {
        background:url(@/assets/xuanzhouwm/activLi.png) no-repeat left center;
      }
    }
  }

  .headWeb {
    position: relative;
    min-width: $primaryWidth;
    z-index: 9;

    .backboxzhe {
      width: 100%;
      height: 160px;
      background-color: transparent;
      cursor: pointer;
    }

    .backbox {
      width: 100%;
      position: absolute;
      top: 0px;
      z-index: -1;

      img {
        width: 100%;
      }
    }

    .headBanner {
      display: flex;
      width: 1200px;
      height: 149px;
      margin: 0px auto;
      align-items: center;
      justify-content: space-between;

      .leftLogo {
        width: 250px;
        height: 83px;
      }

      .searchBoxData {
        width: 320px;
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;

        .smalltop {
          height: 38px;
        }

        .icondata {
          font-size: 20px;
          color: #fff;
          position: absolute;
          right: 0px;
          width: 76px;
          height: 40px;
          background: $primaryColor;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        ::v-deep .el-input__inner {
          border: none;
          height: 38px;
        }
      }

      .imagess {
        width: 413px;
        height: 90px;
        position: relative;

        .rightLogo {
          transition: all 0.5s;
          position: absolute;
          width: 413px;
          height: auto;
          z-index: -1;
          opacity: 0;
          cursor: pointer;
        }

        .chosedimgs {
          z-index: 10;
          opacity: 1;
          object-fit: contain;
        }
      }
    }

    .logoDiv {
      width: 100%;
      // margin: 0px auto;
      display: flex;

      // justify-content: space-between;
      align-items: center;
      height: 120px;
      // padding: 0 20%;
      box-sizing: border-box;
      position: relative;
      z-index: -1;

      // margin-bottom: 20px;
      .logoPart {
        width: $primaryWidth;
        padding: 0 25px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: #fff;

        .logo {
          width: 400px;
        }

        .gif {
          width: 648px;
          // margin-top: 20px;
          margin-left: 96px;
        }
      }
    }

    .haveback {
      width: calc($primaryWidth + 20px);
      margin: 0px auto;
    }

    .navaBox {
      width: 100%;
      height: 50px;
      background-color: $primaryColor;
    }

    .havebackNav {
      width: calc($primaryWidth + 20px);
      margin: 0px auto;
    }

    .lixinrm {
      width: $primaryWidth;
      border-left: 1px solid #fff;
      box-sizing: border-box;
    }

    .searbox {
      justify-content: flex-start;
      padding-right: 244px;
      box-sizing: border-box;
    }
  }

  .headwebCn {

    min-width: calc($primaryWidth + 20px);
  }
  .gif {
    display: none;
    opacity: 0;
  }
  .blockContent {
    display: block;
    opacity: 1;
    transition: all 250ms linear;
  }
  .headNotice{
    height: 28px;
    background: #f1f1f1;
   .notice{
    width: $primaryWidth;
    line-height: 28px;
    margin: 0 auto;
    color: #888586;
    font-size: 11px;
   }
  }
  .time-tianqi {
    font-size: 14px;
    color: #898989;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: -4px;
    left: 111px;

        .weath {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 32px;
            height: 32px;margin-left: 10px;
          }
        }
      }
  </style>
