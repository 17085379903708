<template>
  <div>
    <div class="youqinglink">
      <div class="bottomSelect">
        <div class="title">友情链接：</div>
        <selectCom :selectData="linkSJZF" />
        <selectCom :selectData="linkXZBM" />
        <selectCom :selectData="linkXZZF" />
        <selectCom :selectData="linkYQLJ" />
      </div>
    </div>
    <div class="footWebdongzhi">
      <div v-html="webInfor&&webInfor.WEB_FOOTER_INFORMSTION" style="width: 1100px; margin: 0px auto"></div>
      <!-- 复制后直接粘贴保存不要转回html在保存 -->
      <!-- <div style="width: 1100px; margin: 20px auto; display: flex">
        <p>
          <a
            href="https://bszs.conac.cn/sitename?method=show&id=0D48C2FF77CF3F47E053022819AC22D5"
            style="display: block; float: left; margin-right: 10px"
            target="_blank"
            ><img
              src="https://apisave.jxnn.cn/api/media/convey/download/95bc46d7d872f5440aa3db414eb30fe3/simpleFormat?fileName=95bc46d7d872f5440aa3db414eb30fe3.png"
          /></a>
          <a
            href="http://www.ah12377.cn/"
            style="display: block; float: left; margin-right: 40px"
            target="_blank"
            ><img
              src="https://apisave.jxnn.cn/api/media/convey/download/1a3c32a2095ff7d43e3b429446fed989/simpleFormat?fileName=1a3c32a2095ff7d43e3b429446fed989.png"
          /></a>
        </p>
        <div>
          <p style="text-align: left; color: #666; font-size: 14px">
            版权所有：东至县融媒体中心&nbsp;&nbsp;&nbsp;&nbsp;
            主办：东至县融媒体中心 &nbsp;&nbsp;&nbsp;&nbsp;<a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              style="color: #666; font-size: 14px"
              >皖ICP备11022401号-1</a
            >
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://beian.mps.gov.cn/#/query/webSearch"
              target="_blank"
              style="color: #666; font-size: 14px"
              >皖公网安备 34172102000077号</a
            >
          </p>
          <p
            style="
              text-align: left;
              color: #666;
              font-size: 14px;
              padding-top: 10px;
            "
          >
            未成年人专用举报渠道 举报信箱：dzxwxb2020@126.com
            &nbsp;&nbsp;&nbsp;&nbsp; 本台投稿邮箱 dztbjb@163.com
            &nbsp;&nbsp;&nbsp;&nbsp; 联系电话：0566-7011246
          </p>
          <p
            style="
              text-align: center;
              color: #666;
              font-size: 14px;
              padding-top: 10px;
            "
          >
            <a
              href="http://www.ahdztv.cn/index.php?id=35901"
              target="_blank"
              style="color: #666"
              >网络暴力举报</a
            >
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="http://www.ahdztv.cn/index.php?id=35902"
              target="_blank"
              style="color: #666"
              >涉企举报专区</a
            >
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="http://www.ahdztv.cn/index.php?id=35903"
              target="_blank"
              style="color: #666"
              >未成年人专用举报渠道</a
            >
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.piyao.org.cn/"
              target="_blank"
              style="color: #666"
              >中国互联网联合辟谣平台</a
            >
          </p>
          <p
            style="
              text-align: center;
              color: #666;
              font-size: 14px;
              padding-top: 10px;
            "
          >
            <a href="http://www.ah12377.cn/" target="_blank" style="color: #666"
              >中央网信办（国家网信办）违法和不良信息举报中心</a
            >
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import home from '@/api/index.js'
import selectCom from '@/components/selectCom/selectCom'
export default {
  components: { selectCom },
  data() {
    return {
      linkSJZF: [], // 上级政府
      linkXZBM: [], // 县直部门
      linkXZZF: [], // 乡镇政府
      linkYQLJ: [], // 友情链接
      singArry: ['SJZF', 'XZBM', 'XZZF', 'YQLJ']
    }
  },
  created() {},
  computed: {
    webInfor() {
      return this.$store.state.$webInfor
    },
    configWeb() {
      return this.$store.state.$config
    }
  },
  mounted() {
    this.singArry.forEach((item) => {
      this.getYouqinglink(item)
    })
  },
  methods: {
    getYouqinglink(sign) {
      home
        .getAppOperationGetNavListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then(async (res) => {
          if (sign === 'SJZF') {
            this.linkSJZF = res.data[0].info
          } else if (sign === 'XZBM') {
            this.linkXZBM = res.data[0].info
          } else if (sign === 'XZZF') {
            this.linkXZZF = res.data[0].info
          } else if (sign === 'YQLJ') {
            this.linkYQLJ = res.data[0].info
          }
        })
    }
  }
}
</script>

  <style  lang="scss" scoped>
.youqinglink {
  background: #fff;
  border-top: 6px solid $primaryColor;
  margin-top: 38px;
  .bottomSelect {
    width: $primaryWidth;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .title {
      color: #0863c0;
      font-weight: bold;
      font-size: 18px;
    }
    ::v-deep .el-input__inner {
      width: 262px;
      border-radius: 0;
      border-color: #dddddd;
    }
  }
}
.footWebdongzhi {
  width: 100%;
  min-width: $primaryWidth;
  overflow: hidden;
  border-top: 1px solid #bababa;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  background: #fff;
  a:hover {
    text-decoration: underline;
  }
}
</style>
