<template>
  <div class="headList" @click="todetailsList" :class="type" :style="`height:${height}`"  >
    <div class="sign" v-if="appName !=='yongqiaorm'" >
      <div
      v-if="!signImg"
        :class="configWeb.appName == 'yongqiaowm' ? 'circleTwo' : 'circles'" :style="`background-color:${singColor}`"
      ></div>

      <img v-else :src="signImg" alt=""  class="signImg"/>
    </div>
    <div
      class="titles oneline"
      :class="{ lixinrm: appName === 'lixinrm',titlesxuanzhouWm:type === 'Xuanzhouwm' }"
      :style="`font-size:${fontSize};color:${fontColor}`"
    >
      <span v-if="type === 'xuanzhou'">【{{ items.source }}】</span>
      <span v-if="appName ==='yongqiaorm'">◆</span> {{ items.mainTitle }}
    </div>
    <div class="times" :class="{ lixinrm: appName === 'lixinrm' }" v-if="!type">
      {{ items.releaseTime | times }}
    </div>
  </div>
</template>

<script>
import props from './props.js'
import { toDetails } from '../../utils/toDetail'
export default {
  mixins: [props],
  filters: {
    times(data) {
      if (data) {
        const one = data.split(' ')
        if (one.length) {
          const two = one[0].split('-')
          two.shift()
          return two.join('-')
        }
      }
    }
  },
  data() {
    return {
      appName: this.$store.state.$config.appName
    }
  },
  mounted() {
    // console.log(this.items, '$$$$$$$$$$$$$$5555')
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  },
  methods: {
    todetailsList() {
      toDetails(this.items)
    }
  }
}
</script>

<style lang="scss" scoped>
.headList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  .circles {
    width: 4px;
    height: 4px;
    background-color: $primaryColor;
    border-radius: 10px;
    margin-right: 10px;
  }
  .circleTwo {
    width: 6px;
    height: 6px;
    margin-right: 10px;
    background: url("~@/assets/yongqiaowm/arr1.gif") ;
    background-size: contain;
  }
  .titles {
    // font-size: 14px;
    color: #707070;
    width: calc(100% - 110px);
    cursor: pointer;
  }
  .titlesxuanzhouWm {
    // font-size: 14px;
    color: #707070;
    width: calc(100% - 11px);
    cursor: pointer;
  }
  .titles:hover{
    color: $primaryColor!important;
  }
  .times {
    margin-left: 12px;
    text-align: right;
    width: 80px;
    color: #b6b6b6;
    font-size: 14px;
  }
}
.lixinrm:hover {
  color: $primaryHoverColor;
  opacity: 0.8;
}
.xuanzhou,
.xuanzhouList {
  justify-content: flex-start;
  height: 38px;
  line-height: 38px;
  .circles {
    width: 5px;
    height: 5px;
    background-color: #c3c3c3;
    border-radius: 0px;
    margin-left: 20px;
    margin-right: 30px;
  }
  .titles {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    width: calc(100% - 82px);
  }
}
.xuanzhouList {
  height: 34px;
  line-height: 34px;
  .titles {
    font-weight: 400;
  }
}
.signImg{
  margin-right: 4px;
}
.fengtai{
  justify-content:start;
  .titles{
    width: calc(100% - 22px);
  }
  .sign{
    margin-top:4px
  }
}
</style>
