// 默认图配置：@/assets/defaultImg/${appName}.png defaultImg文件夹中加入网站默认图 命名appName
// // 郎溪
// module.exports = {
//   baseUrl: 'https://apiadmin.yun.fst1994.net/api/', // 基地址 http://192.168.5.231/library/searchLibrary https://server.yun.fst1994.net/login?appName=langxirm
//   appName: 'langxirm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveType: 'noBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZSYDH',
//     bannersign: 'WZT',
//     Mbannersign: 'WZTM',
//     adver: false
//   },
//   haveLogin: false, // 是否有登录功能没有登录就没有评论和点赞
//   haveShare: true, // 是否需要分享
//   justifyContent: 'flex-end', // 分页居中显示
//   hiddenEditor: false, // 是否隐藏编辑人
//   heat: true, // 是否隐藏热度
//   source: false// 是否隐藏来源
// }
// 埇桥文明
// module.exports = {
//   baseUrl: 'https://apiadmin.yun.fst1994.net/api/', // 基地址 http://192.168.5.231/library/searchLibrary https://server.yun.fst1994.net/login?appName=langxirm
//   appName: 'yongqiaowm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveType: 'noBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZSYDH',
//     bannersign: 'DBLBT',
//     redsign: 'WZPH',
//     adver: true
//   },
//   haveLogin: false, // 是否有登录功能没有登录就没有评论和点赞
//   haveShare: true, // 是否需要分享
//   justifyContent: 'flex-end', // 分页居中显示
//   hiddenEditor: true, // 是否隐藏编辑人
//   heat: false, // 是否隐藏热度
//   source: true, // 是否隐藏来源
//   hiddenzuozhe: true// 是否隐藏来源
// }
// 埇桥新闻
// module.exports = {
//   baseUrl: 'https://apiadmin.yun.fst1994.net/api/', // 'http://192.168.5.231:10010/api/',
//   appName: 'yongqiaorm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveType: 'noBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'DBFL',
//     bannersign: 'DBLBT',
//     redsign: 'WZPH',
//     adver: true
//   },
//   haveLogin: false, // 是否有登录功能没有登录就没有评论和点赞
//   haveShare: true, // 是否需要分享
//   justifyContent: 'flex-end', // 分页居中显示
//   hiddenEditor: true, // 是否隐藏编辑人
//   heat: false, // 是否隐藏热度
//   source: true, // 是否隐藏来源
//   hiddenzuozhe: true// 是否隐藏来源
// }
// 绩溪
// module.exports = {
//   // baseUrl: 'https://apiadmin.yun.fst1994.net/api/',
//   baseUrl: 'http://192.168.5.231:10010/api/', // 测试地址
//   appName: 'jixirm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveTyp: 'haveBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZDH', // 网站导航标识
//     bannersign: 'WZLOGO', // 网站背景图标识
//     adver: true // 用广告查询背景还是用导航查询
//   },
//   haveLogin: false, // 登录
//   haveShare: false, // 是否需要分享
//   justifyContent: 'center', // 分页居中显示
//   hiddenEditor: true, // 是否隐藏编辑人
//   heat: true, // 是否隐藏热度
//   source: false// 是否隐藏来源
// }

// 宣州
// module.exports = {
//   baseUrl: 'https://apiadmin.yun.fst1994.net/api/',
//   // baseUrl: 'http://192.168.5.231:10010/api/', // 测试地址113
//   appName: 'xuanzhourm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveTyp: 'noBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZSYDH', // 网站导航标识
//     bannersign: 'WZLOGO', // 网站背景图标识
//     adver: false, // 用广告查询背景还是用导航查询
//     weatherKey: '5cefc7c265d5277a41cc3c4a2143a0bc'
//   },
//   haveLogin: false, // 登录
//   haveShare: true, // 是否需要分享
//   justifyContent: 'center', // 分页居中显示
//   hiddenEditor: false, // 是否隐藏编辑人
//   heat: true, // 是否隐藏热度
//   source: false// 是否隐藏来源
// }

// 利辛
// module.exports = {
//   // baseUrl: 'https://apiadmin.yun.fst1994.net/api/',
//   baseUrl: 'http://192.168.5.231:10010/api/', // 测试地址113
//   appName: 'lixinrm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveTyp: 'noBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZSYDH', // 网站导航标识
//     bannersign: 'WZLOGO', // 网站背景图标识
//     adver: false, // 用广告查询背景还是用导航查询
//     weatherKey: '5cefc7c265d5277a41cc3c4a2143a0bc'
//   },
//   haveLogin: false, // 登录
//   haveShare: true, // 是否需要分享
//   justifyContent: 'center', // 分页居中显示
//   hiddenEditor: false, // 是否隐藏编辑人
//   heat: true, // 是否隐藏热度
//   source: false// 是否隐藏来源
// }

// 青阳
// module.exports = {
//   baseUrl: 'https://apiadmin.yun.fst1994.net/api/',
//   // baseUrl: 'http://192.168.5.231:10010/api/',
//   appName: 'qingyangrm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveType: 'noBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZSYDH',
//     bannersign: 'WZT',
//     adver: false
//   },
//   haveLogin: false, // 是否有登录功能没有登录就没有评论和点赞
//   haveShare: false, // 是否需要分享
//   justifyContent: 'flex-end', // 分页居中显示
//   hiddenEditor: true, // 是否隐藏编辑人
//   heat: false, // 是否隐藏热度
//   source: true// 是否隐藏来源
// }

// 东至
// module.exports = {
//   baseUrl: 'http://192.168.5.231:10010/api/', // 基地址 http://192.168.5.231/library/searchLibrary https://server.yun.fst1994.net/login?appName=langxirm
//   appName: 'dongzhirm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
//   headWeb: {
//     naveType: 'minBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZSBDH',
//     bannersign: 'DBLBT',
//     redsign: 'WZPH',
//     adver: true
//   },
//   haveLogin: false, // 是否有登录功能没有登录就没有评论和点赞
//   haveShare: true, // 是否需要分享
//   justifyContent: 'flex-end', // 分页居中显示
//   hiddenEditor: false, // 是否隐藏编辑人
//   heat: true, // 是否隐藏热度
//   source: false// 是否隐藏来源
// }
// 宣州文明
// module.exports = {
//   baseUrl: 'https://apiadmin.yun.fst1994.net/api/',

//   // baseUrl: 'http://192.168.5.231:10010/api/', // 基地址 http://192.168.5.231/library/searchLibrary https://server.yun.fst1994.net/login?appName=langxirm
//   appName: 'xuanzhouwm', // appName
//   serveCode: '20230118102056',
//   $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
//   $aesIv: '37fa77f6a3b0462d',
//   headWeb: {
//     naveType: 'minBanner', // indexBack 大背景 、noBanner 就一个导航
//     sign: 'WZSBDH',
//     bannersign: 'DBLBT',
//     redsign: 'WZPH',
//     adver: true,
//     weatherKey: '5cefc7c265d5277a41cc3c4a2143a0bc' // AES加密密匙的偏移量
//   },
//   haveLogin: false, // 是否有登录功能没有登录就没有评论和点赞
//   haveShare: true, // 是否需要分享
//   justifyContent: 'flex-end', // 分页居中显示
//   hiddenEditor: false, // 是否隐藏编辑人
//   heat: true, // 是否隐藏热度
//   source: false// 是否隐藏来源
//  process.env.NODE_ENV === 'production' ? '/xuanzhouwmw/' :'/./'
// }

// 凤台新闻网
module.exports = {
  baseUrl: 'https://apiadmin.yun.fst1994.net/api/', // 基地址 http://192.168.5.231/library/searchLibrary https://server.yun.fst1994.net/login?appName=langxirm
  appName: 'fengtaiapp', // appName
  serveCode: '20230118102056',
  $aesKey: '385f33cb91484b04a177828829081ab7', // AES加密密匙
  $aesIv: '37fa77f6a3b0462d', // AES加密密匙的偏移量
  headWeb: {
    naveType: 'minBanner', // indexBack 大背景 、noBanner 就一个导航
    sign: 'WZSBDH', // 网站导航标识
    bannersign: 'DBLBT',
    redsign: 'WZPH',
    adver: true
  },
  haveLogin: false, // 是否有登录功能没有登录就没有评论和点赞
  haveShare: true, // 是否需要分享
  justifyContent: 'flex-end', // 分页居中显示
  hiddenEditor: false, // 是否隐藏编辑人
  heat: true, // 是否隐藏热度
  source: false, // 是否隐藏来源
  hiddenzuozhe: false// 是否隐藏来源
}
