<template>
    <div class="multipleSlider">

        <div class="myboxdata">
        <div
          class="titles smallline"
          @mouseenter="mouChange(index)"
          @click="clicChange(index)"
          v-for="(item,index) in mytitles"
          :key="index"
        >
          <span>{{item&&item.navigationName?item.navigationName:''}}</span>
        </div>
        <div class="rightBlock">
            <img src="@/assets/fengtaiapp/leftIcon.png" alt=""  @click="$refs.slider.$emit('slidePre')" style="    padding-top: 3px"/>
            <img src="@/assets/fengtaiapp/rightIcon.png" alt=""  @click="$refs.slider.$emit('slideNext')"/>
        </div>

      </div>

      <!-- 轮播图 -->
      <div class="slideBox">
        <slider class="slider boxs" ref="slider" :options="options">
          <slideritem class="slideritemBox boxs" v-for="(item,index) in specialData" :key="index">
                <div class="slideImg"  @click="imgLinkTo(item)">
              <!-- <img  v-if="item.topicCover&&JSON.parse(item.topicCover).length" :src="JSON.parse(item.cover)[0]|uploadFilters" alt=""> -->
              <img
                v-if="item.topicCover&&JSON.parse(item.topicCover).length"
                :src="JSON.parse(item.topicCover)[0] | uploadFilters"
                alt
              />
              <img v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
            </div>
            <div class="tuitit " @click="imgLinkTo(item)" >{{item.topicName}}</div>

          </slideritem>
        </slider>
      </div>
    </div>
  </template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import props from './props.js'
import home from '@/api/index.js'
import { recommentJump } from '@/utils/toDetail'
export default {
  components: { slider, slideritem },
  mixins: [props],
  data() {
    return {
      options: {
        appName: this.$store.state.$config.appName,
        currentPage: 0,
        infinite: 4,
        slidesToScroll: 1,
        loop: true,
        pagination: false,
        speed: 300,
        loopedSlides: 4,
        autoplay: 3000
        // freeze: false
      },
      specialData: [],
      choosedIndex: 0
    }
  },
  created() {
    this.focusOnImgList() // 专题数据
  },
  methods: {

    mouChange(index) {
      if (this.changeType === 'hover') {
        this.choosedIndex = index
      }
    },
    clicChange(index) {
      if (this.changeType === 'click') {
        this.choosedIndex = index
      }
      this.toOtherData()
    },

    // 跳转到其他页面
    toOtherData() {
      const item = this.mytitles[this.choosedIndex]
      if (item.columnId && item.wenming) { // 文明实践列表跳转
        this.$router.push({
          path: '/newsListWenMing',
          query: {
            columnId: item.columnId
          }
        })
      } else if (item.otherLink) {
        window.open(item.otherLink)
      } else if (item.dataLink !== '') {
        let colum = ''
        if (item.columnSign) {
          if (item.dataLink.indexOf('?') === -1) {
            colum = '?column=' + item.columnSign
          } else {
            colum = '&column=' + item.columnSign
          }
        }
        if (this.$route.fullPath !== item.dataLink) {
          const { href } = this.$router.resolve({
            path: item.dataLink + colum
          })
          window.open(href)
        }
      }
    },
    focusOnImgList() {
      home
        .getAppOperationSelectPage({
          appName: this.$store.state.$config.appName,
          page: 1,
          pageSize: 30
        })
        .then((res) => {
          if (res.success) {
            this.specialData = res.data.items
          }
        })
    },
    // 图片跳转
    imgLinkTo(val) {
      console.log(val, '%%%recommentJump%%')
      recommentJump(val, '/newslistWenMing')
      //     this.$router.push({
      //       path: '/newsList',
      //       query: {
      //         id: val.id,
      //         name: val.topicName
      //       }
      //     })
    }
  }
}
</script>

  <style  lang="scss" scoped>
    .myboxdata {
    display: flex;
    width: 100%;
  height: 40px;
  border-bottom: 1px solid #F3F3F6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  .titles {
    height: 40px;
    line-height: 40px;
    font-size: 23px;
    position: relative;
    color: $primaryColor;
    font-weight: bold;
    border-bottom: 2px solid #D80006;
    cursor: pointer;
    .icons {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 5px solid $primaryColor;
    }
  }
  .multipleSlider {
    width: 100%;
    margin: 0px auto;
    border-bottom: 1px solid #F3F3F6;
    .slideBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      .slider {
        width: 100% ;
        overflow: hidden;
        padding: 0px 0px;
      }
      .slideritemBox {
        width: 272px;
        margin-left: 9px;
        margin-right: 9px;
        display: flex;
        flex-direction: column;

        .slideImg {
          width: 100%;
        //   border-radius: 6px;
          overflow: hidden;
          position: relative;
          height: 107px;
          cursor: pointer;
          transition: all 0.5s;
          &:hover {
            // transform: scale(1.1, 1.1);
          }
          img {
            width: 100%;
            height: 100%;
          }
        }

      }
      .icondata {
        width: 24px;
        height: 78px;
        background: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .tuitit{
    min-height: 60px;
    cursor: pointer;
    font-size: 17px;
    line-height: 28px;
    color: #40403F;
    margin: 10px 8px;
    width: 97%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

  }
  .tuitit:hover{
    color: $primaryColor;
  }
  .slider-container{
            white-space: unset!important;
        }

    .rightBlock{
        margin-top: 10px;
        img{
            width:18px ;
            height: 18px;
            margin-left: 5px;
            cursor: pointer;
        }
    }
  </style>
