<template>
    <div
      class="headWeb"
      :class="{ headwebCn: naveType == 'indexBack' && backimgbox }"
    >
      <!-- 这里是飘红头首页不需要传值默认indexBack -->
      <div
      class="backboxzhe"
      v-if="naveType == 'indexBack' && backimgbox"
      @click="bannerToOther(this.backimgbox)"
    ></div>
    <div class="backbox" v-if="naveType == 'indexBack' && backimgbox">
      <img :src="backimgbox.advertisementImage | uploadFilters" alt />
    </div>

      <div class="head"  :class="{ headCn: naveType == 'indexBack' && backimgbox }"  >
        <div class="headNotice">
            <div class="notice">
               {{ dataTime }}  {{time}}  {{currentDayOfWeek}}
            </div>
            <div class="headRight">
                新闻热线：0554-8685531 / 0554-8629907
                <span class="linIm" v-for="(items, indexs) in bangzhuInfo" :key="indexs" @click="toPage(items)">
                    {{ items.navigationName }}</span>
            </div>

        </div>
        <div class="homeLogo">
            <div class="primaryWidth">
             <!-- 根据广告图表示获取logo图片 -->
            <div class="zhanweiOne">
                <img
                class="logo"
                v-if="logo.length"
                :src="logo[0].advertisementImage | uploadFilters"
                alt
                />
            </div>
               <!-- 头部右边图 -->
               <div class="mydefaultImage">
                <img
                class="gif"
                v-for="(item, index) in bannerL"
                :key="index"
                :class="index === chosedIndex ? 'blockContent' : ''"
                :src="item.advertisementImage | uploadFilters"
                 @click="adverToPageXuan(item)"
                alt
                />
            </div>
            <div class="sideRight">
                <div class="search">
                    <el-input
                    size="smalltop"
                    style="width: 130px; height: 35px;border:none"
                    v-model="textTitle"
                    placeholder="请输入关键字"
                    @change="toSearch"
                     >
                    </el-input>
                    <img
                        class="icon"
                        src="@/assets/fengtaiapp/searchIcon.png"
                        alt
                         @click="toSearch"
                        />
                </div>
                <div class="imgsBox">
                    <div class="imgItem" v-for="(item, index) in imgsBox" :key="index">
                        <img
                        class="icon"
                        :src="item.img"
                        alt
                        />
                        <img
                        class="hover"
                        :src="item.imgH "
                        :style="{left: item.left }"
                        alt
                        />
                    </div>
                </div>
            </div>
            </div>

        </div>
        <div class="commHeader">
          <!--导航-->
          <div class="nav">
            <ul class="commHeaderTextDaohang">
              <li
                class="commHeaderText"
                v-for="(item, index) in myallTitles[0].info"
                :key="index"
                :class="
                  activecolumn === item.columnSign
                    ? 'actvie'
                    : activecolumn === item.navigationSign
                    ? 'actvie'
                    : ''
                "
                @click="toPage(item)"
              >
                {{ item.navigationName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import home from '@/api/index.js'
import props from './props.js'
import { timeToString } from '@/utils/utils'
import { adverTopage, jumpTopage } from '@/utils/toDetail'
export default {
  mixins: [props],
  components: {},
  data() {
    return {
      dataTime: timeToString(new Date(), '日期'),
      time: '',
      chosedIndex: 0,
      currentDayOfWeek: '',
      backimgbox: '',
      bannerL: [],
      myallTitles: [{ info: [] }],
      logo: [],
      activecolumn: 'WZSY',
      textTitle: '',
      bangzhuInfo: [],
      imgsBox: [{
        img: require('@/assets/fengtaiapp/headImg1.png'),
        imgH: require('@/assets/fengtaiapp/headImg1H.jpg'),
        left: '-40px'
      },
      {
        img: require('@/assets/fengtaiapp/headImg2.png'),
        imgH: require('@/assets/fengtaiapp/headImg2H.png'),
        left: '0px'
      },
      {
        img: require('@/assets/fengtaiapp/headImg3.png'),
        imgH: require('@/assets/fengtaiapp/headImg3H.png'),
        left: '40px'
      },
      {
        img: require('@/assets/fengtaiapp/headImg4.png'),
        imgH: require('@/assets/fengtaiapp/headImg4H.png'),
        left: '80px'
      }]
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    },
    loginStatus() {
      const staus = this.$store.state.token && this.$store.state.userInfo
      return staus
    }
  },
  created() {
    const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    const today = new Date().getDay()
    this.currentDayOfWeek = daysOfWeek[today]
    setInterval(() => {
      this.time = timeToString(new Date(), '时间')
    }, 1000)
    this.adverDataLink('WZLOGO', 'logo')
    this.mynav() // 这里是我的导航
    if (this.naveType === 'indexBack') {
      if (this.adver) {
        if (this.redsign) {
          this.adverDataLink(this.redsign, 'pc')
        }
      } else {
        this.bigBckg() // 网站飘红
      }
    }
    if (this.naveType !== 'noBanner') {
      if (this.adver) {
        if (this.bannersign) {
          this.adverDataLink(this.bannersign, 'banner') // 广告查图片
        }
      }
    }
    if (this.$route.query.sing) {
      this.activecolumn = this.$route.query.sing
    }
    if (this.$route.query.column) {
      this.activecolumn = this.$route.query.column
    }
    if (this.$route.query.keyWord || this.$route.query.id) {
      this.activecolumn = ''
    }
    this.getYouqinglink()
  },
  methods: {

    adverToPageXuan(val) {
      if (!val.advertisementIink && val.dateUrl) {
        const { href } = this.$router.resolve({
          path: val.dateUrl
        })
        window.open(href)
        // this.$router.push({ path: val.dateUrl })
      } else {
        adverTopage(val)
      }
    },
    getYouqinglink() {
      home
        .getAppOperationGetNavListBySign({
          sign: 'BZZX',
          appName: this.configWeb.appName
        })
        .then(async (res) => {
          if (res.data && res.success) {
            this.bangzhuInfo = res.data[0].info
          }
        })
    },
    toSearch() {
      // 跳转搜索页面
      if (this.textTitle) {
        console.log(this.textTitle)
        if (this.$route.path === '/newslist') {
          this.$emit('toSearch', this.textTitle)
        } else {
          this.$router.push({
            path: '/newslist',
            query: {
              keyWord: this.textTitle
            }
          })
        }
      } else {
        this.$message({
          showClose: true,
          message: '请输入关键字',
          type: 'error'
        })
      }
    },
    indexWidth() {
      this.$emit('indexWidth')
    },

    navToHome() {
      const { href } = this.$router.resolve({
        path: '/'
      })
      window.open(href)
    },
    adverDataLink(sign, name) {
      home
        .getAppOperationGetAdvListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (name === 'pc') {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            } else if (name === 'logo') {
              console.log(sign, '>EEE')
              this.logo = res.data // logo
            } else {
              this.bannerL = res.data
              setInterval(() => {
                if (this.chosedIndex === this.bannerL.length - 1) {
                  this.chosedIndex = 0
                } else {
                  this.chosedIndex++
                }
              }, 2000)
            }
          }
        })
    },
    // 广告图跳转
    bannerToOther(item) {
      adverTopage(item)
    },
    toPage(item) {
      console.log(item, '>>%%%出文明')
      jumpTopage(item)
    },
    mynav() {
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: this.sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success) {
            this.myallTitles = res.data
          }
        })
    },
    // 大的飘红接口 不要删每个网站必要的
    bigBckg() {
      if (this.redsign) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.redsign,
            appName: this.configWeb.appName
          })
          .then((res) => {
            console.log('res飘红头', res)
            if (res.success && res.data && res.data.length) {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            }
          })
      }
    },

    // 回首页
    goBackIndex() {
      this.$router.push({ path: '/' })
    }

  }
}
</script>

 <style lang="scss" scoped>
  .head {
    position: relative;
    background: #fff;
  }
  .headCn{
    width: $primaryWidth;
    margin: 0 auto;
  }
  //导航logo
  .homeLogo {
    height: 180px;
    width: 100%;
    background:url(@/assets/fengtaiapp/headBg.png)repeat-x left top;

    .primaryWidth{
    height: 180px;
    width: $primaryWidth;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;

    }
    .zhanweiOne {
      width:243px;
      height: 76x;
      position: relative;
      img {
        width: 100%;
      }
    }
    .mydefaultImage {
      width: 550px;
      height: 75px;
      max-width: 550px;
      img {
        width: auto;
        height: 100%;
      }
    }
  }
  //导航文字
  .commHeader {
    height: 63px;
    background:#f5f5f7;
    border-top:2px solid #D70000;
    .nav {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      width: $primaryWidth;
      margin:0 auto;
    }
    .el-button + .el-button {
      margin-left: 0 !important;
    }
    .commHeaderTextDaohang {
      box-sizing: border-box;
      width: $primaryWidth;
      height: 63px;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      .commHeaderText {
        list-style: none;
        font-size: 18px;
        font-weight: 400;
        color: #000;
        cursor: pointer;
        line-height: 63px;
        &:hover {
          color: $primaryHoverColor;
        }
      }
      .commHeaderText :hover,
      .actvie {
        color: $primaryHoverColor;
      }
    }
  }

  .headWeb {
    position: relative;
    min-width: $primaryWidth;
    z-index: 9;

    .backboxzhe {
      width: 100%;
      height: 160px;
      background-color: transparent;
      cursor: pointer;
    }

    .backbox {
      width: 100%;
      position: absolute;
      top: 0px;
      z-index: -1;

      img {
        width: 100%;
      }
    }

    .headBanner {
      display: flex;
      width: 1200px;
      height: 149px;
      margin: 0px auto;
      align-items: center;
      justify-content: space-between;

      .leftLogo {
        width: 250px;
        height: 83px;
      }

      .searchBoxData {
        width: 320px;
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;

        .smalltop {
          height: 38px;
        }

        .icondata {
          font-size: 20px;
          color: #fff;
          position: absolute;
          right: 0px;
          width: 76px;
          height: 40px;
          background: $primaryColor;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        ::v-deep .el-input__inner {
          border: none;
          height: 38px;
        }
      }

      .imagess {
        width: 413px;
        height: 90px;
        position: relative;

        .rightLogo {
          transition: all 0.5s;
          position: absolute;
          width: 413px;
          height: auto;
          z-index: -1;
          opacity: 0;
          cursor: pointer;
        }

        .chosedimgs {
          z-index: 10;
          opacity: 1;
          object-fit: contain;
        }
      }
    }

    .haveback {
      width: calc($primaryWidth + 20px);
      margin: 0px auto;
    }

    .navaBox {
      width: 100%;
      height: 50px;
      background-color: $primaryColor;
    }

    .havebackNav {
      width: calc($primaryWidth + 20px);
      margin: 0px auto;
    }

    .lixinrm {
      width: $primaryWidth;
      border-left: 1px solid #fff;
      box-sizing: border-box;
    }

    .searbox {
      justify-content: flex-start;
      padding-right: 244px;
      box-sizing: border-box;
    }
  }

  .headwebCn {

    min-width: calc($primaryWidth + 20px);
  }
  .gif {
    display: none;
    opacity: 0;
    cursor: pointer;
  }
  .blockContent {
    display: block;
    opacity: 1;
    transition: all 250ms linear;
  }
  .headNotice{
    height:50px;
    background: #f5f5f7;
    border-bottom: 1px solid #ebebeb;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   .notice{
    margin: 0 auto;
    color: #A0A0A0;
    font-size: 16px;
   }
   .headRight{
    margin: 0 auto;
    color: #A0A0A0;
    font-size: 16px;
    span{
        padding-left:10px;
        cursor: pointer;
    }
    span:hover{
        color: $primaryHoverColor;
    }
   }
  }
  .sideRight{
     display:flex;

    .search{
        width:160px;
        height:40px;
        border-radius:10px;
        border:1px solid #E5DACF;
        display:flex;margin-right:35px;
        align-items:center;
        ::v-deep .el-input__inner{
            height: 34px;
            border:none;
            background:#ffffff;
        }
        .icon{
            width:27px;
            height:25px;
            cursor: pointer;
        }
    }
    .imgsBox{
        display:flex;
        height:100%;
        align-items:center;
        position:relative;
       .imgItem{
         .icon{
            width:46px;
            height:50px;
            cursor: pointer;
         }
         .hover{
            display:none;
            position:absolute;
            top:33px;
            width:126px;
            height:126px;

           z-index: 9999;
         }
       }
       .imgItem:hover .hover{
          display:block;
       }
       }
    }

  </style>
