<template>
    <div class="bigSideBox">
      <div
        class="bigHead boxs"
        :class="{bigHeadline:lineType=='smallline',nolineHead:lineType=='smallnoline'}"
      >
        <div class="myboxdata">
          <div
            class="titles"
            @mouseenter="mouChange(index)"
            @click="clicChange(index)"
            :class="{tiActive:choosedIndex==index,smallline:lineType=='smallline'||lineType=='smallnoline'}"
            v-for="(item,index) in mytitles"
            :key="index"
          >
            <span>{{item&&item.navigationName?item.navigationName:''}}</span>

          </div>
        </div>

      </div>
      <!-- 标题底下的内容啊 -->
      <div class="hohecon">
        <div v-for="(item,index) in mytitles" :key="index">
          <div v-show="choosedIndex==index">
            <slot :name="item.id" v-if="item&&item.id"></slot>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import props from './props.js'
export default {
  mixins: [props],
  data() {
    return {
      choosedIndex: 0
    }
  },
  methods: {
    mouChange(index) {
      if (this.changeType === 'hover') {
        this.choosedIndex = index
      }
    },
    clicChange(index) {
      if (this.changeType === 'click') {
        this.choosedIndex = index
      }
      this.toOtherData()
    },

    // 跳转到其他页面
    toOtherData() {
      const item = this.mytitles[this.choosedIndex]
      if (item.columnId && item.wenming) { // 文明实践列表跳转
        this.$router.push({
          path: '/newsListWenMing',
          query: {
            columnId: item.columnId
          }
        })
      } else if (item.otherLink) {
        window.open(item.otherLink)
      } else if (item.dataLink !== '') {
        let colum = ''
        if (item.columnSign) {
          if (item.dataLink.indexOf('?') === -1) {
            colum = '?column=' + item.columnSign
          } else {
            colum = '&column=' + item.columnSign
          }
        }
        if (this.$route.fullPath !== item.dataLink) {
          const { href } = this.$router.resolve({
            path: item.dataLink + colum
          })
          window.open(href)
        }
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .bigSideBox {
    width: 100%;
    .hohecon {
      width: 100%;
      margin-top: 14px;
    }
  }
  .bigHead {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .myboxdata {
      display: flex;
    }
    .titles {
      font-size: 17px;
      position: relative;
      padding: 11px 30px;
      cursor: pointer;
      border-radius: 0px 24px 0px 0px;
    }
    .tiActive {
      background: $primaryColor;
      color: #fff;
    }
    .smallline {
      background: #fff;
      color: #333;
      padding: 0 0px;
      margin-left: 20px;
      margin-right: 20px;
      &:first-child {
        margin-left: 0px;
      }
    }
    .tiActive.smallline {
      font-weight: bold;
      color: $primaryColor;
      border-bottom: 2px solid $primaryColor;
    }
    .morecon {
      font-size: 14px;
      color: #858585;
      cursor: pointer;
    }
    .morecon:hover{
      color: $primaryColor;
    }
  }
  .bigHeadline {
    border-bottom: 1px solid #bfbfbf;
  }
  .nolineHead {
    border-bottom: none;
  }
  </style>
