// 推荐轮播图
export default {
  props: {
    items: {
      require: false,
      type: Object
    },
    fontSize: {
      require: false,
      type: String,
      default: '16px'
    },
    type: {
      require: false,
      type: String,
      default: ''
    },
    fontColor: {
      require: false,
      type: String,
      default: '#707070'
    },
    singColor: {
      require: false,
      type: String,
      default: ''
    },
    height: {
      require: false,
      type: String,
      default: '38px'
    },
    signImg: {
      require: false,
      type: String,
      default: null
    }

  }
}
