import { render, staticRenderFns } from "./yongqiaoXw.vue?vue&type=template&id=55d2a76b&scoped=true&"
import script from "./yongqiaoXw.vue?vue&type=script&lang=js&"
export * from "./yongqiaoXw.vue?vue&type=script&lang=js&"
import style0 from "./yongqiaoXw.vue?vue&type=style&index=0&id=55d2a76b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d2a76b",
  null
  
)

export default component.exports