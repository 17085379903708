<template>
    <div class="headList" @click="todetailsList" :class="type" :style="`height:${height}`"  >
      <div
        class="titles oneline"
        :style="`font-size:${fontSize};color:${fontColor}`"
      >
        {{ items.mainTitle }}
      </div>

    </div>
  </template>

<script>
import props from './props.js'
import { toDetails } from '../../utils/toDetail'
export default {
  mixins: [props],
  filters: {
    times(data) {
      if (data) {
        const one = data.split(' ')
        if (one.length) {
          const two = one[0].split('-')
          two.shift()
          return two.join('-')
        }
      }
    }
  },
  data() {
    return {
      appName: this.$store.state.$config.appName
    }
  },
  mounted() {
    // console.log(this.items, '5555')
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  },
  methods: {
    todetailsList() {
      toDetails(this.items)
    }
  }
}
</script>

  <style lang="scss" scoped>
  .headList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 38px;
    padding:11px 0;

    .titles {
       font-size: 19px;
      color: #333333;
      width:100%;
      cursor: pointer;
      font-weight:Bold;
    }

    .titles:hover{
      color: $primaryColor!important;
    }
    .times {
      margin-left: 12px;
      text-align: right;
      width: 80px;
      color: #b6b6b6;
      font-size: 14px;
    }
  }

  </style>
