<template>
  <div class="carouselSign" v-if="list.length > 0" :style="{ height: height }">
    <div class="listBox">
      <el-carousel class="lunbobox" :class="{ 'One': list.length == 1 }" height="100%" :direction="direction" :autoplay="true"
        trigger="click">
        <el-carousel-item v-for="(item, index) in list" :key="index + 'hh'">
          <div class="myitemsCon" @click="adverToPageXuan(item)">
            <img class="imagescon" v-if="item.advertisementImage" :src="item.advertisementImage | uploadFilters" alt />
            <img class="imagescon" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
            <!-- <div class="tuitit boxs oneline" :class="titleType">{{item.mainTitle}}</div> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import home from '@/api/index.js'
import { adverTopage } from '@/utils/toDetail'
export default {
  props: {
    sign: {
      type: String,
      default: '',
      required: true
    },
    height: {
      type: String,
      default: '110px'
    },
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  data() {
    return {
      list: [],
      appName: this.$store.state.$config.appName
    }
  },
  created() {
    this.getAdvise()
  },
  methods: {
    getAdvise() {
      home
        .getAppOperationGetAdvListBySign({
          sign: this.sign,
          appName: this.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            this.list = res.data
            // console.log(this.list, '669998877')
          } else {
            this.$emit('deleteOne', this.sign)
          }
        })
    },
    adverToPageXuan(val) {
      if (!val.advertisementIink && val.dateUrl) {
        const { href } = this.$router.resolve({
          path: val.dateUrl
        })
        window.open(href)
        // this.$router.push({ path: val.dateUrl })
      } else {
        adverTopage(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carouselSign {
  .listBox {
    width: 100%;
    height: 100%;

    .lunbobox {
      width: 100%;
      height: 100%;

      .myitemsCon {
        width: 100%;
        height: 100%;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      ::v-deep .el-carousel__indicators--horizontal,
      ::v-deep .el-carousel__indicators--vertical {
        // right: 20px;
        // left: auto;
        // transform: translateX(0%);
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        right: unset;
        top: unset;
      }

      ::v-deep .el-carousel__button {
        width: 7px;
        height: 7px;
        border-radius: 7px;
        opacity: 1;
      }

      ::v-deep .el-carousel__indicator--vertical {
        padding: 4px;
      }

      ::v-deep .el-carousel__indicator.is-active button {
        background-color: $primaryColor;
      }
    }

    .One {
      ::v-deep .el-carousel__button {
        opacity: 0;
      }
    }
  }
}
</style>
