<template>
  <div>
    <div
      class="headWeb"
      :class="{ headwebCn: naveType == 'indexBack' && backimgbox }"
    >
      <!-- 这里是飘红头 -->
      <template>
        <div class="headTitle">
          <div style="width: 1200px; margin: 0 auto; display: flex; align-items: center;">
            <div class="notice" >
              <span
                >{{currentTime}} {{weekdays}} 农历 {{ nongli }} <b>&nbsp;埇桥</b>
              </span>
              <span style="margin-left: 5px; margin-top: -5px">
                <iframe
                  name="weather_inc"
                  src="http://i.tianqi.com/index.php?c=code&amp;id=52&amp;icon=1&amp;num=3"
                  width="150"
                  height="25"
                  frameborder="0"
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                ></iframe>
              </span>
              <span style="color: #666; line-height: 32px; float: left"
                >互联网违法和不良信息举报电话：0557-3707011
                举报邮箱：yongqiaonews@163.com</span
              >
            </div>
            <div class="searchBoxDataBottom" v-if="search == 'navSearch'">
            <el-input
              size="smalltop"
              style="width: 141px; height: 22px"
              v-model="textTitle"
              placeholder="请输入关键字"
              @change="toSearch"
            >
              <i class="el-icon-search" slot="append" @click="toSearch"></i>
            </el-input>
          </div>
          </div>
        </div>
        <div class="logoBox">
          <img
            class="logo"
            v-if="logo.length"
            :src="logo[0].advertisementImage | uploadFilters"
            alt
          />
        </div>
        <div
          class="navaBox"
          style="height: 40px"
          :class="{
            qingyanghavebackNav: naveType == 'indexBack' && backimgbox,
          }"
        >
          <div class="navContent">
            <div
              class="itemCon"
              :class="activecolumn === item.columnSign ? 'actvie' : ''"
              v-for="(item, index) in myallTitles[0].info"
              :key="index"
              @click="toPage(item)"
            >
              <span>{{ item.navigationName }}</span>
            </div>
          </div>
        </div>
      </template>

      <!-- 宣州 -->

      <loginBox
        :loginVisible="loginShowVisible"
        @changeLoginVisible="changeLoginVisibleStatus"
      ></loginBox>
    </div>
  </div>
</template>

<script>
import home from '@/api/index.js'

import { adverTopage } from '@/utils/toDetail'
import { getLunar } from '@/utils/getLunar'
import loginBox from '../loginIn/loginIn'
export default {
  props: {
    naveType: {
      require: false,
      type: String,
      default: 'indexBack' // indexBack 大背景 、noBanner 就一个导航
    },
    search: {
      require: false,
      type: String,
      default: 'navSearch' // 查询的文职
    },
    sign: {
      require: true,
      type: String,
      default: '' // 查询的文职
    },
    redsign: {
      require: false,
      type: String,
      default: '' // 飘红广告
    },
    bannersign: {
      require: false,
      type: String,
      default: '' // banner广告
    },
    showNavigation: {
      require: false,
      type: Boolean,
      default: true // 查询的文职
    },
    adver: {
      require: false,
      type: Boolean,
      default: false // 查询的文职
    }
  },
  components: {
    loginBox
  },
  data() {
    return {
      bannerLTwo: [],
      chosedIndex: 0,
      loginShowVisible: false, // 登录注册
      textTitle: '',
      backimgbox: '',
      bannerL: [],
      myallTitles: [{ info: [] }],
      logo: [],
      weatherImage: require('@/assets/tianqi/sun.png'),
      weatherInfo: null,
      currentTime: '',
      weekdays: '',
      nongli: '',
      activecolumn: ''
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    },
    loginStatus() {
      const staus = this.$store.state.token && this.$store.state.userInfo
      return staus
    }
  },
  created() {
    // 获取logo
    this.adverDataLink('WZLOGO', 'logo')
    this.mynav() // 这里是我的导航
    this.getTime()
    if (this.$route.query.keyWord) {
      this.textTitle = this.$route.query.keyWord
    }
    if (this.naveType === 'indexBack') {
      if (this.adver) {
        // console.log('this.naveType', this.naveType)
        if (this.redsign) {
          this.adverDataLink(this.redsign, 'pc')
        }
      } else {
        this.bigBckg() // 网站飘红
      }
    }
    if (this.naveType !== 'noBanner') {
      if (this.adver) {
        // console.log(this.bannersign, '6')
        if (this.bannersign) {
          this.adverDataLink(this.bannersign, 'banner') // 广告查图片
        }
      } else {
        this.bannerData() // 这里是我的banner头页面
      }
    }
    this.nongli = getLunar(this.getDate(new Date()))
    this.activecolumn = this.$route.query.column || ''
  },
  methods: {

    getDate(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return year + '-' + month + '-' + day
    },
    indexWidth() {
      this.$emit('indexWidth')
    },
    navToHome() {
      // this.$router.push({ path: '/' })
      const { href } = this.$router.resolve({
        path: '/'
      })
      window.open(href)
    },
    backImgFilter(url) {
      if (url) {
        if (
          url.indexOf(this.$store.state.$config.baseUrl) === -1 &&
          url.indexOf('http://') === -1 &&
          url.indexOf('https://') === -1
        ) {
          return `${this.$store.state.$config.baseUrl}media${url}`
        } else {
          return url
        }
      }
    },
    adverDataLink(sign, name) {
      home
        .getAppOperationGetAdvListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          console.log(res.data, '>>飘红头//', sign)
          if (res.success && res.data && res.data.length) {
            if (name === 'pc') {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            } else if (name === 'logo') {
              this.logo = res.data // logo
            } else {
              this.logoData = res.data
            }
          }
        })
    },
    // 点击登录按钮的点击事件
    loginBtnClick() {
      if (this.loginStatus) {
        // 如果有登陆信息则显示退出登录的按钮
        this.$confirm('确认退出登录吗').then(async () => {
          this.$store.dispatch('loginOut')
        })
      } else {
        // 显示登录按钮
        this.loginShowVisible = true
      }
    },
    changeLoginVisibleStatus() {
      this.loginShowVisible = false
    },
    toSearch() {
      // 跳转搜索页面
      if (this.textTitle) {
        if (this.$route.path === '/newslist') {
          this.$emit('toSearch', this.textTitle)
        } else {
          this.$router.push({
            path: '/newslist',
            query: {
              keyWord: this.textTitle
            }
          })
        }
      } else {
        this.$message({
          showClose: true,
          message: '请输入关键字',
          type: 'error'
        })
      }
    },
    toOtherWeb() {
      adverTopage(this.backimgbox)
    },
    bannerToOther(item) {
      adverTopage(item)
    },
    toPage(item) {
      if (item.otherLink) {
        window.open(item.otherLink)
      } else {
        let colum = ''
        if (item.columnSign) {
          if (item.dataLink.indexOf('?') === -1) {
            colum = '?column=' + item.columnSign
          } else {
            colum = '&column=' + item.columnSign
          }
        }
        const isHasSameRou = this.$route.fullPath.indexOf(item.dataLink) !== -1 // 是不是具有相同的路径
        const isHasSameParams = this.$route.fullPath.indexOf(colum) !== -1 // 是不是具有相同的参数
        if (colum.length <= 0) {
          if (item.dataLink !== this.$route.fullPath) {
            const { href } = this.$router.resolve({
              path: item.dataLink + colum
            })
            window.open(href)
          }
        } else {
          if (!isHasSameRou || !isHasSameParams) {
            const { href } = this.$router.resolve({
              path: item.dataLink + colum
            })
            window.open(href)
          }
        }
      }
    },
    mynav() {
      // console.log('www')
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: this.sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          // console.log('res', res)
          if (res.success) {
            // console.log('daohangdaohang', res)
            this.myallTitles = res.data
          }
        })
    },
    // 大的飘红接口
    bigBckg() {
      if (this.redsign) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.redsign,
            appName: this.configWeb.appName
          })
          .then((res) => {
            // console.log('res', res)
            if (res.success && res.data && res.data.length) {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            }
          })
      }
    },
    // 头banner 图
    bannerData() {
      home
        .getAppOperationGetAdvListBySign({
          sign: this.bannersign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          // console.log('我的banner头', res)
          if (res.success && res.data && res.data.length) {
            this.bannerL = res.data // banner广告
            setInterval(() => {
              if (this.chosedIndex === this.bannerL.length - 1) {
                this.chosedIndex = 0
              } else {
                this.chosedIndex++
              }
            }, 3000)
          }
        })
      console.log(this.bannersignTwo, '===jinll吗')
      if (this.bannersignTwo) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.bannersignTwo,
            appName: this.configWeb.appName
          })
          .then((res) => {
            if (res.success && res.data && res.data.length) {
              this.bannerLTwo = res.data // banner广告
            }
          })
      }
    },
    // 回首页
    goBackIndex() {
      this.$router.push({ path: '/' })
    },

    // 时间
    getTime() {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) month = `0${month}`
      if (day < 10) day = `0${day}`
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getSeconds()
      this.currentTime = year + '年' + month + '月' + day + '日' + ' ' + hours + ':' + minutes + ':' + seconds
      const weekdays = {
        0: '星期日',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六'
      }
      const weekday = new Date(date).getDay()
      this.weekdays = weekdays[weekday]
    }
  }
}
</script>

    <style lang="scss" scoped>
.headWeb {
  position: relative;
  min-width: 100%;
  margin: 0 auto;
  font-family: 微软雅黑;
  .backboxzhe {
    width: 100%;
    height: 160px;
    background-color: transparent;
    cursor: pointer;
  }
  .backbox {
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: -1;

    img {
      width: 100%;
    }
  }
  .navaBox {
    width: 100%;
    margin: 0 auto;
    height: 40px;
    background: $primaryColor;
  }
  .havebackNav {
    width: calc($primaryWidth + 20px);
    margin: 0px auto;
  }

  .navContent {
    width: $primaryWidth;
    height: 100%;
    display: flex;
    position: relative;
    margin: 0px auto;

    .itemCon {
      height: 100%;
      display: flex;
      width: 137px;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      font-size: 15px;
      color: #fff;
      background: url("~@/assets/yongqiaorm/jx_icon.png") right center no-repeat;
      cursor: pointer;
    }
    .itemCon:last-child {
      background: none;
    }
    .itemCon:hover,
    .actvie {
      color: #ffdb7c;
    }

    .searchBox {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 20px;
      height: 100%;

      .icondata {
        font-size: 18px;
        margin-left: 8px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
      }

      .loginBtn {
        margin-left: 10px;
        font-size: 16px;
        text-align: center;
      }

      .el-button--primary {
        background: transparent !important;
        border: unset;
      }
    }

    // .itemConAct{
    //   color: $primaryColor;
    //   background-color: #fff;
    // }
    ::v-deep .el-input__inner {
      border: none;
    }
  }
  .logoBox {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    height: 173px;
    .logo {
      width: 100%;
      height: 173px;
      object-fit: cover;
    }
  }
  .headTitle {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 32px;
    background: #f2f2f2;
    box-sizing: border-box;
    padding: 0 10px;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    .notice {
      display: flex;
      align-items: center;
      margin-left: 0px;
    }

    .searchBoxDataBottom {
      width: 141px;
      height: 22px;
      border: 1px solid #adabab;
      border-radius: 3px;
      outline: medium;
      background-color: #fff;
      margin-left: 7px;
      ::v-deep .el-input-group__append {
        background: none;
        border: none;
        padding: 0 10px 0 0;
        cursor: pointer;
      }
      ::v-deep .el-input__inner {
        border: none;
        height: 22px;
        padding: 0 8px;
      }
    }
  }
}
</style>
