<!--  -->
<template>
<div>
  <el-select v-model="selectName" @change="toOtherLink">
    <el-option
      v-for="item in selectData"
      :key="item.id"
      :label="item.navigationName"
      :value="item.otherLink"
    ></el-option>
  </el-select>
</div>
</template>

<script>
export default {
  props: {
    selectData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectName: ''
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    // this.selectName = this.selectData[0].otherLink
    this.$nextTick(() => {
      console.log(this.selectName, '>>#####', this.selectData)
    })
  },
  methods: {
    toOtherLink(val) {
      if (val) {
        window.open(val)
      }
    }
  }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.select {
  width: 100%;
}

::v-deep .el-input__inner {
  width: 208px;
  height: 33px;
  border: 1px solid #b5b5b5;
  border-radius: 6px;
}
::v-deep .el-input__suffix {
  top: 4px;
}
</style>
