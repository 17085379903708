<template>
  <div
    class="headWeb"
    :class="{ headwebCn: naveType == 'indexBack' && backimgbox }"
  >
    <!-- 这里是飘红头 -->
    <div class="backbox" v-if="backimgbox">
      <img :src="backimgbox.advertisementImage | uploadFilters" alt />
    </div>
    <!-- 埇桥 -->
    <div class="headInfo">
      <div class="w1200 row">
        <p>您好！欢迎来到埇桥文明网 {{ currentTime }} {{ weekdays }}</p>
        <p>
          互联网违法和不良信息举报电话：0557-3707607&nbsp;&nbsp;&nbsp;&nbsp;举报邮箱：yongqiaonews@163.com
        </p>
      </div>
    </div>
    <div class="headTopBanner">
      <div class="logoDiv">
        <div class="logoPart">
          <img
            class="logo"
            v-if="logo.length"
            :src="logo[0].advertisementImage | uploadFilters"
            alt
          />

          <img
            class="gif"
            v-for="(item, index) in logoData"
            :key="index"
            :class="index === activeIndex ? 'blockContent' : ''"
            :src="item.advertisementImage | uploadFilters"
            alt
          />
        </div>
      </div>
    </div>
    <div
      class="navaBox w1200"
      style="height: 40px"
      :class="{ qingyanghavebackNav: naveType == 'indexBack' && backimgbox }"
    >
      <div class="navContent">
        <div
          class="itemCon"
          :class="activecolumn === item.columnSign ? 'actvie' : ''"
          v-for="(item, index) in myallTitles[0].info"
          :key="index"
          @click="toPage(item)"
        >
          <span>{{ item.navigationName }}</span>
        </div>
      </div>
    </div>
    <div class=" searchItembg">
      <div class="w1200 searchItem">
      <div class="time-tianqi">
        <!-- <span>{{ currentTime }} </span> -->
        <iframe
          allowtransparency="true"
          frameborder="0"
          width="360"
          height="36"
          scrolling="no"
          src="http://tianqi.2345.com/plugin/widget/index.htm?s=3&amp;z=2&amp;t=0&amp;v=0&amp;d=3&amp;bd=0&amp;k=&amp;f=&amp;q=1&amp;e=1&amp;a=0&amp;c=58122&amp;w=360&amp;h=36&amp;align=left"
        ></iframe>
      </div>
      <div
        class="searchBox"
        v-if="search == 'navSearch'"

      >
        <el-input
          size="small"
          v-model="textTitle"
          placeholder="请输入关键字"
          @change="toSearch"
        >
          <el-button type="primary" slot="append" class="searchBtn"
            >搜索</el-button
          >
        </el-input>
      </div>
    </div>
  </div>
    <loginBox
      :loginVisible="loginShowVisible"
      @changeLoginVisible="changeLoginVisibleStatus"
    ></loginBox>
  </div>
</template>

<script>
import home from '@/api/index.js'
import props from './props.js'
import { adverTopage } from '@/utils/toDetail'
import loginBox from '../loginIn/loginIn'
import axios from 'axios'

export default {
  mixins: [props],
  components: {
    loginBox
  },
  data() {
    return {
      chosedIndex: 0,
      loginShowVisible: false, // 登录注册
      textTitle: '',
      backimgbox: '',
      bannerL: [],
      myallTitles: [{ info: [] }],
      logoData: [],
      weatherImage: require('@/assets/tianqi/sun.png'),
      weatherInfo: null,
      currentTime: '',
      logo: [],
      activeIndex: 0,
      activecolumn: '',
      weekdays: ''
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    },
    loginStatus() {
      const staus = this.$store.state.token && this.$store.state.userInfo
      return staus
    }
  },
  created() {
    // 获取logo
    this.adverDataLink('WZLOGO', 'logo')
    this.mynav() // 这里是我的导航
    console.log('this.naveType', this.redsign)
    if (this.naveType === 'indexBack') {
      if (this.adver) {
        // console.log('this.naveType', this.naveType)
        if (this.redsign) {
          this.adverDataLink(this.redsign, 'pc')
        }
      } else {
        this.bigBckg() // 网站飘红
      }
    } else {
      if (this.redsign) {
        this.adverDataLink(this.redsign, 'pc')
      }
    }
    if (this.bannersign) {
      this.adverDataLink(this.bannersign, 'banner') // 顶部轮播
    }
    // this.getWeather()
    this.getTime()
    if (this.$route.query.keyWord) {
      this.textTitle = this.$route.query.keyWord
    }
    this.activecolumn = this.$route.query.column || ''

    // banner 定时器
  },
  methods: {
    navToHome() {
      // this.$router.push({ path: '/' })
      const { href } = this.$router.resolve({
        path: '/'
      })
      window.open(href)
    },
    backImgFilter(url) {
      if (url) {
        if (
          url.indexOf(this.$store.state.$config.baseUrl) === -1 &&
          url.indexOf('http://') === -1 &&
          url.indexOf('https://') === -1
        ) {
          return `${this.$store.state.$config.baseUrl}media${url}`
        } else {
          return url
        }
      }
    },
    adverDataLink(sign, name) {
      // console.log(sign)
      home
        .getAppOperationGetAdvListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (name === 'pc') {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            } else if (name === 'logo') {
              this.logo = res.data // logo
            } else {
              this.logoData = res.data
              /// setTimeout
              setInterval(() => {
                if (this.activeIndex === this.logoData.length - 1) {
                  this.activeIndex = 0
                } else {
                  this.activeIndex++
                }
              }, 2000)
            }
          }
        })
    },
    // 点击登录按钮的点击事件
    loginBtnClick() {
      if (this.loginStatus) {
        // 如果有登陆信息则显示退出登录的按钮
        this.$confirm('确认退出登录吗').then(async () => {
          this.$store.dispatch('loginOut')
        })
      } else {
        // 显示登录按钮
        this.loginShowVisible = true
      }
    },
    changeLoginVisibleStatus() {
      this.loginShowVisible = false
    },
    toSearch() {
      // 跳转搜索页面
      if (this.textTitle) {
        this.$router.push({
          path: '/newslist',
          query: {
            keyWord: this.textTitle
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请输入关键字',
          type: 'error'
        })
      }
    },
    toOtherWeb() {
      adverTopage(this.backimgbox)
    },
    bannerToOther(item) {
      adverTopage(item)
    },
    toPage(item) {
      if (item.otherLink) {
        window.open(item.otherLink)
      } else {
        let colum = ''
        if (item.columnSign) {
          if (item.dataLink.indexOf('?') === -1) {
            colum = '?column=' + item.columnSign
          } else {
            colum = '&column=' + item.columnSign
          }
        }
        const isHasSameRou = this.$route.fullPath.indexOf(item.dataLink) !== -1 // 是不是具有相同的路径
        const isHasSameParams = this.$route.fullPath.indexOf(colum) !== -1 // 是不是具有相同的参数
        if (colum.length <= 0) {
          if (item.dataLink !== this.$route.fullPath) {
            const { href } = this.$router.resolve({
              path: item.dataLink + colum
            })
            window.open(href)
          }
        } else {
          if (!isHasSameRou || !isHasSameParams) {
            const { href } = this.$router.resolve({
              path: item.dataLink + colum
            })
            window.open(href)
          }
        }
      }
    },
    mynav() {
      // console.log('www')
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: this.sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          // console.log('res', res)
          if (res.success) {
            this.myallTitles = res.data
          }
        })
    },
    // 大的飘红接口
    bigBckg() {
      console.log('>>>大的飘红接口', this.redsign, '>>S')
      if (this.redsign) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.redsign,
            appName: this.configWeb.appName
          })
          .then((res) => {
            // console.log('res', res)
            if (res.success && res.data && res.data.length) {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            }
          })
      }
    },
    // 头banner 图
    bannerData() {
      home
        .getAppOperationGetAdvListBySign({
          sign: this.bannersign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          // console.log('我的banner头', res)
          if (res.success && res.data && res.data.length) {
            this.bannerL = res.data // banner广告
            setInterval(() => {
              if (this.chosedIndex === this.bannerL.length - 1) {
                this.chosedIndex = 0
              } else {
                this.chosedIndex++
              }
            }, 3000)
          }
        })
    },
    // 回首页
    goBackIndex() {
      this.$router.push({ path: '/' })
    },
    // 获取天气
    getWeather() {
      axios
        .get('https://restapi.amap.com/v3/ip', {
          params: {
            key: this.$store.state.$config.headWeb.weatherKey
          }
        })
        .then((ret) => {
          console.log(ret.data, '获取天气')
          if (ret.data.status === '1') {
            axios
              .get('https://restapi.amap.com/v3/weather/weatherInfo', {
                params: {
                  city: ret.data.adcode,
                  key: this.$store.state.$config.headWeb.weatherKey
                }
              })
              .then((res) => {
                console.log(res.data)
                if (res.data.status === '1') {
                  this.weatherInfo = res.data.lives[0]
                  if (res.data.lives[0].weather === '晴') {
                    this.weatherImage = require('@/assets/tianqi/sun.png')
                  } else if (
                    res.data.lives[0].weather === '少云' ||
                    res.data.lives[0].weather === '晴间多云' ||
                    res.data.lives[0].weather === '多云'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/duoyun.png')
                  } else if (res.data.lives[0].weather === '暴雪') {
                    this.weatherImage = require('@/assets/tianqi/baoxue.png')
                  } else if (
                    res.data.lives[0].weather === '暴雨' ||
                    res.data.lives[0].weather === '大暴雨' ||
                    res.data.lives[0].weather === '特大暴雨' ||
                    res.data.lives[0].weather === '强阵雨' ||
                    res.data.lives[0].weather === '暴雨-大暴雨' ||
                    res.data.lives[0].weather === '大暴雨-特大暴雨' ||
                    res.data.lives[0].weather === '极端降雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/baoyu.png')
                  } else if (
                    res.data.lives[0].weather === '大雪' ||
                    res.data.lives[0].weather === '大雪-暴雪' ||
                    res.data.lives[0].weather === '阵雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/daxue.png')
                  } else if (
                    res.data.lives[0].weather === '大雨' ||
                    res.data.lives[0].weather === '大雨-暴雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/dayu.png')
                  } else if (
                    res.data.lives[0].weather === '阵雨' ||
                    res.data.lives[0].weather === '雷阵雨' ||
                    res.data.lives[0].weather === '雷阵雨并伴有冰雹' ||
                    res.data.lives[0].weather === '强雷阵雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/leizhengyu.png')
                  } else if (
                    res.data.lives[0].weather === '霾' ||
                    res.data.lives[0].weather === '中度霾' ||
                    res.data.lives[0].weather === '重度霾' ||
                    res.data.lives[0].weather === '严重霾' ||
                    res.data.lives[0].weather === '雾' ||
                    res.data.lives[0].weather === '浓雾' ||
                    res.data.lives[0].weather === '强浓雾' ||
                    res.data.lives[0].weather === '轻雾' ||
                    res.data.lives[0].weather === '大雾' ||
                    res.data.lives[0].weather === '特强浓雾'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/wumai.png')
                  } else if (
                    res.data.lives[0].weather === '小雪' ||
                    res.data.lives[0].weather === '雪' ||
                    res.data.lives[0].weather === '小雪-中雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/xiaoxue.png')
                  } else if (
                    res.data.lives[0].weather === '雨' ||
                    res.data.lives[0].weather === '小雨' ||
                    res.data.lives[0].weather === '小雨-中雨' ||
                    res.data.lives[0].weather === '冻雨' ||
                    res.data.lives[0].weather === '毛毛雨/细雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/xiaoyu.png')
                  } else if (res.data.lives[0].weather === '阴') {
                    this.weatherImage = require('@/assets/tianqi/ying.png')
                  } else if (
                    res.data.lives[0].weather === '雨夹雪' ||
                    res.data.lives[0].weather === '雨雪天气' ||
                    res.data.lives[0].weather === '阵雨夹雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/yujiaxue.png')
                  } else if (
                    res.data.lives[0].weather === '中雪' ||
                    res.data.lives[0].weather === '中雪-大雪'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/zhongxue.png')
                  } else if (
                    res.data.lives[0].weather === '中雨' ||
                    res.data.lives[0].weather === '中雨-大雨'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/zhongyu.png')
                  } else if (
                    res.data.lives[0].weather === '有风' ||
                    res.data.lives[0].weather === '微风' ||
                    res.data.lives[0].weather === '平静' ||
                    res.data.lives[0].weather === '和风' ||
                    res.data.lives[0].weather === '清风' ||
                    res.data.lives[0].weather === '强风/劲风' ||
                    res.data.lives[0].weather === '疾风' ||
                    res.data.lives[0].weather === '大风' ||
                    res.data.lives[0].weather === '烈风' ||
                    res.data.lives[0].weather === '风暴' ||
                    res.data.lives[0].weather === '狂爆风' ||
                    res.data.lives[0].weather === '飓风' ||
                    res.data.lives[0].weather === '热带风暴' ||
                    res.data.lives[0].weather === '龙卷风' ||
                    res.data.lives[0].weather === '浮尘' ||
                    res.data.lives[0].weather === '扬沙' ||
                    res.data.lives[0].weather === '沙尘暴' ||
                    res.data.lives[0].weather === '强沙尘暴'
                  ) {
                    this.weatherImage = require('@/assets/tianqi/feng.png')
                  } else {
                    this.weatherImage = require('@/assets/tianqi/weizhi.png')
                  }
                }
              })
          }
        })
    },
    // 时间
    getTime() {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) month = `0${month}`
      if (day < 10) day = `0${day}`
      this.currentTime = year + '年' + month + '月' + day + '日'
      const weekdays = {
        0: '星期日',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六'
      }
      const weekday = new Date(date).getDay()
      this.weekdays = weekdays[weekday]
    },
    goToWeather() {
      window.open('http://tianqi.2345.com/shushan/71872.htm')
    }
  }
}
</script>

  <style lang="scss" scoped>
.headWeb {
  position: relative;
  min-width: $primaryWidth;

  .backboxzhe {
    width: 100%;
    height: 160px;
    background-color: transparent;
    cursor: pointer;
  }

  .backbox {
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: -1;

    img {
      width: 100%;
    }
  }

  .headBanner {
    display: flex;
    width: $primaryWidth;
    height: 149px;
    margin: 0px auto;
    align-items: center;
    justify-content: space-between;

    .leftLogo {
      width: 250px;
      height: 83px;
    }

    .searchBoxData {
      width: 320px;
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;

      .smalltop {
        height: 38px;
      }

      .icondata {
        font-size: 20px;
        color: #fff;
        position: absolute;
        right: 0px;
        width: 76px;
        height: 40px;
        background: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ::v-deep .el-input__inner {
        border: none;
        height: 38px;
      }
    }

    .imagess {
      width: 413px;
      height: 90px;
      position: relative;

      .rightLogo {
        transition: all 0.5s;
        position: absolute;
        width: 413px;
        height: auto;
        z-index: -1;
        opacity: 0;
        cursor: pointer;
      }

      .chosedimgs {
        z-index: 10;
        opacity: 1;
        object-fit: contain;
      }
    }
  }

  .logoDiv {
    width: 100%;
    // margin: 0px auto;
    display: flex;

    // justify-content: space-between;
    align-items: center;
    height: 120px;
    // padding: 0 20%;
    box-sizing: border-box;
    position: relative;
    z-index: -1;

    // margin-bottom: 20px;
    .logoPart {
      width: $primaryWidth;
      padding: 0 0px;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .logo {
        width: 251px;
      }

      .gif {
        width: 600px;
        opacity: 0;
        transition: all 3s;
        margin-left: 96px;
        position: absolute;
        right: 0px;
        top: none;
      }
      .gifImg {
        width: 600px;
      }
      .blockContent {
        opacity: 1;
        /* transition: all 3s; */
      }
    }
  }

  .haveback {
    width: calc($primaryWidth + 20px);
    margin: 0px auto;
  }

  .navaBox {
    width: 100%;
    height: 40px;
    background: url("~@/assets/yongqiaowm/navbg.gif") no-repeat center;
  }
  .w1200 {
    width: $primaryWidth;
    margin: 0 auto;
  }
  .havebackNav {
    width: calc($primaryWidth + 20px);
    margin: 0px auto;
  }

  .navContent {
    width: $primaryWidth;
    height: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;
    margin: 0px auto;

    .itemCon {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      font-size: 15px;
      color: #fff;
      background: url("~@/assets/yongqiaowm/navbg.gif") right top no-repeat;
      cursor: pointer;
    }
    .itemCon:last-child {
      background: none;
    }
    .itemCon:hover,
    .actvie {
      color: #ffdb7c;
    }

    .searchBox {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 20px;
      height: 100%;

      .icondata {
        font-size: 18px;
        margin-left: 8px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
      }

      .loginBtn {
        margin-left: 10px;
        font-size: 16px;
        text-align: center;
      }

      .el-button--primary {
        background: transparent !important;
        border: unset;
      }
    }

    // .itemConAct{
    //   color: $primaryColor;
    //   background-color: #fff;
    // }
    ::v-deep .el-input__inner {
      border: none;
    }
  }

  .searbox {
    justify-content: flex-start;
    padding-right: 244px;
    box-sizing: border-box;
  }
}

.headwebCn {
  min-width: calc($primaryWidth + 20px);
}

.headTopBanner {
  display: flex;
  height: 233px;
  margin: 0px auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .toptLogo {
    width: 662px;
    height: 123px;
    margin-bottom: 50px;
    cursor: pointer;
  }

  .searchBoxDataBottom {
    width: 610px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;

    .smalltop {
      height: 38px;
    }

    .icondata {
      font-size: 20px;
      color: #fff;
      position: absolute;
      right: 0px;
      width: 76px;
      height: 42px;
      background: $primaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ::v-deep .el-input__inner {
      border: none;
      height: 42px;
    }
  }
}
.headInfo {
  background: $primaryColor;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  color: #fff;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.searchItembg{
  background-image: linear-gradient(to top bottom, #f7f7f9, #f8f8f8);

}
.searchItem {
  display: flex;
  justify-content: space-between;
   align-items: center;

  padding: 5px 0 0 ;
  .searchBtn {
    width: 42px;
    height: 32px;
    background: #d41220;
    cursor: pointer;
    border: 0 none;
    text-align: center;
    color: #fff;
    overflow: hidden;
    padding: 2px 0;
  }
}
::v-deep .el-input-group__append {
  border: none;
}
::v-deep .el-input__inner {
  border-right: none;
}
.time-tianqi {
  // margin-top: -5px;
}
</style>
