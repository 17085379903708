<template>
  <div
    class="headWeb"
    :class="{ headwebCn: naveType == 'indexBack' && backimgbox }"
  >
    <!-- 这里是飘红头首页不需要传值默认indexBack -->
    <div
      class="backboxzhe"
      v-if="naveType == 'indexBack' && backimgbox"
      @click="bannerToOther(this.backimgbox)"
    ></div>
    <div class="backbox" v-if="naveType == 'indexBack' && backimgbox">
      <img :src="backimgbox.advertisementImage | uploadFilters" alt />
    </div>

    <div class="head"  :class="{ headCn: naveType == 'indexBack' && backimgbox }"  >
      <div class="homeLogo">
        <!-- 根据广告图表示获取logo图片 -->
        <div class="zhanweiOne">
          <img
            class="logo"
            v-if="logo.length"
            :src="logo[0].advertisementImage | uploadFilters"
            alt
          />
        </div>
        <!-- 头部右边图 -->
        <div class="mydefaultImage">
          <img
            class="gif"
            v-for="(item, index) in bannerL"
            :key="index"
            :class="index === chosedIndex ? 'blockContent' : ''"
            :src="item.advertisementImage | uploadFilters"
            alt
          />
        </div>
      </div>
      <div class="commHeader">
        <!--导航-->
        <div class="nav">
          <ul class="commHeaderTextDaohang">
            <li
              class="commHeaderText"
              v-for="(item, index) in myallTitles[0].info"
              :key="index"
              :class="
                activecolumn === item.columnSign
                  ? 'actvie'
                  : '' || activecolumn === item.navigationSign
                  ? 'actvie'
                  : ''
              "
              @click="toPage(item)"
            >
              {{ item.navigationName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import home from '@/api/index.js'
import props from './props.js'
import { adverTopage, jumpTopage } from '@/utils/toDetail'
export default {
  mixins: [props],
  components: {},
  data() {
    return {
      chosedIndex: 0,
      backimgbox: '',
      bannerL: [],
      myallTitles: [{ info: [] }],
      logo: [],
      activecolumn: ''
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    },
    loginStatus() {
      const staus = this.$store.state.token && this.$store.state.userInfo
      return staus
    }
  },
  created() {
    this.adverDataLink('WZLOGO', 'logo')
    this.mynav() // 这里是我的导航
    if (this.naveType === 'indexBack') {
      if (this.adver) {
        if (this.redsign) {
          this.adverDataLink(this.redsign, 'pc')
        }
      } else {
        this.bigBckg() // 网站飘红
      }
    }
    if (this.naveType !== 'noBanner') {
      if (this.adver) {
        if (this.bannersign) {
          this.adverDataLink(this.bannersign, 'banner') // 广告查图片
        }
      }
    }
    if (this.$route.query.sing) {
      this.activecolumn = this.$route.query.sing
    } else {
      this.activecolumn = this.$route.query.column
    }
  },
  methods: {
    indexWidth() {
      this.$emit('indexWidth')
    },
    navToHome() {
      const { href } = this.$router.resolve({
        path: '/'
      })
      window.open(href)
    },
    adverDataLink(sign, name) {
      // console.log(sign)
      home
        .getAppOperationGetAdvListBySign({
          sign: sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (name === 'pc') {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            } else if (name === 'logo') {
              this.logo = res.data // logo
            } else {
              this.bannerL = res.data
              setInterval(() => {
                if (this.chosedIndex === this.bannerL.length - 1) {
                  this.chosedIndex = 0
                } else {
                  this.chosedIndex++
                }
              }, 2000)
            }
          }
        })
    },

    // 广告图跳转
    bannerToOther(item) {
      adverTopage(item)
    },
    toPage(item) {
      console.log(item, '>>%%%出文明')
      jumpTopage(item)
    },
    mynav() {
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: this.sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success) {
            this.myallTitles = res.data
          }
        })
    },
    // 大的飘红接口 不要删每个网站必要的
    bigBckg() {
      if (this.redsign) {
        home
          .getAppOperationGetAdvListBySign({
            sign: this.redsign,
            appName: this.configWeb.appName
          })
          .then((res) => {
            console.log('res飘红头', res)
            if (res.success && res.data && res.data.length) {
              this.$emit('indexWidth')
              this.backimgbox = res.data[0] // 飘红头
            }
          })
      }
    },

    // 回首页
    goBackIndex() {
      this.$router.push({ path: '/' })
    }

  }
}
</script>

  <style lang="scss" scoped>
.head {
  position: relative;
  background: url("~@/assets/dongzhi/headBg.png") repeat-x top left;

}
.headCn{
  width: $primaryWidth;
  margin: 0 auto;
}
//导航logo
.homeLogo {
  height: 229px;
  width: $primaryWidth;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  .zhanweiOne {
    width: 385px;
    height: 90px;
    img {
      width: 100%;
    }
  }
  .mydefaultImage {
    position: absolute;
    width: auto;
    height: 229px;
    right: 0;
    top: 0;
    img {
      width: auto;
      height: 100%;
    }
  }
}
//导航文字
.commHeader {
  height: 53px;
  background: $primaryColor;
  min-width: $primaryWidth;
  .nav {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: $primaryWidth;
  }
  .el-button + .el-button {
    margin-left: 0 !important;
  }
  .commHeaderTextDaohang {
    box-sizing: border-box;
    width: $primaryWidth;
    height: 53px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    .commHeaderText {
      list-style: none;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      padding: 0 30px;
      line-height: 53px;
      &:hover {
        color: #fff;
        background-color: #0f9cf8;
      }
    }
    .commHeaderText :hover,
    .actvie {
      background-color: #0f9cf8;
    }
  }
}

.headWeb {
  position: relative;
  min-width: $primaryWidth;
  z-index: 9;

  .backboxzhe {
    width: 100%;
    height: 160px;
    background-color: transparent;
    cursor: pointer;
  }

  .backbox {
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: -1;

    img {
      width: 100%;
    }
  }

  .headBanner {
    display: flex;
    width: 1200px;
    height: 149px;
    margin: 0px auto;
    align-items: center;
    justify-content: space-between;

    .leftLogo {
      width: 250px;
      height: 83px;
    }

    .searchBoxData {
      width: 320px;
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;

      .smalltop {
        height: 38px;
      }

      .icondata {
        font-size: 20px;
        color: #fff;
        position: absolute;
        right: 0px;
        width: 76px;
        height: 40px;
        background: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ::v-deep .el-input__inner {
        border: none;
        height: 38px;
      }
    }

    .imagess {
      width: 413px;
      height: 90px;
      position: relative;

      .rightLogo {
        transition: all 0.5s;
        position: absolute;
        width: 413px;
        height: auto;
        z-index: -1;
        opacity: 0;
        cursor: pointer;
      }

      .chosedimgs {
        z-index: 10;
        opacity: 1;
        object-fit: contain;
      }
    }
  }

  .logoDiv {
    width: 100%;
    // margin: 0px auto;
    display: flex;

    // justify-content: space-between;
    align-items: center;
    height: 120px;
    // padding: 0 20%;
    box-sizing: border-box;
    position: relative;
    z-index: -1;

    // margin-bottom: 20px;
    .logoPart {
      width: $primaryWidth;
      padding: 0 25px;
      margin: 0 auto;
      box-sizing: border-box;
      background-color: #fff;

      .logo {
        width: 400px;
      }

      .gif {
        width: 648px;
        // margin-top: 20px;
        margin-left: 96px;
      }
    }
  }

  .haveback {
    width: calc($primaryWidth + 20px);
    margin: 0px auto;
  }

  .navaBox {
    width: 100%;
    height: 50px;
    background-color: $primaryColor;
  }

  .havebackNav {
    width: calc($primaryWidth + 20px);
    margin: 0px auto;
  }

  .lixinrm {
    width: $primaryWidth;
    border-left: 1px solid #fff;
    box-sizing: border-box;
  }

  .searbox {
    justify-content: flex-start;
    padding-right: 244px;
    box-sizing: border-box;
  }
}

.headwebCn {
  min-width: calc($primaryWidth + 20px);
}
.gif {
  display: none;
  opacity: 0;
}
.blockContent {
  display: block;
  opacity: 1;
  transition: all 250ms linear;
}
</style>
