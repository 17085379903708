<template>
  <div class="footWeb">

    <div class="footer">
      <div v-html="webInfor && webInfor.WEB_FOOTER_INFORMSTION"></div>
        <!-- <p style="width: 577px;  line-height: 26px">
          备案号：<a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #fff"
            target="_blank"
            title="备案"
            >皖ICP备2021007464号</a
          >&nbsp;<a
            href="https://www.beian.gov.cn/portal/registerSystemInfo"
            style="color: #fff"
            target="_blank"
            title="1"
            >皖公网安备 34130202000462号</a
          >
          &nbsp;Copyright @ www.yongqiaonews.com All Right Reserved.
        </p>
        <p style="width: 577px;  line-height: 26px">
          主办单位：中共宿州市埇桥区委宣传部 &nbsp; 联系电话：0557-3707011
          &nbsp; 邮箱：yongqiaonews@163.com
        </p>
        <p style="width: 577px;  line-height: 26px">
          地址：安徽省宿州市汴河中路751号区委大院&nbsp;
          <a
            href="http://first1994.mikecrm.com/WAANM4X"
            target="_blank"
            style="color: #fff"
            >互联网投诉举报入口</a
          >-》&nbsp;
          <a
            href="http://www.ahsz.tv/bdyw/p/127275.html"
            target="_blank"
            style="color: #fff"
            >涉企举报专区</a
          ><span style="text-wrap: wrap">-》</span>
        </p>

      <p  style="width: 60px; margin-top: 40px;   position: absolute;
        left: 557px;
        top: 0px;">
        <span id="_ideConac"
          ><a
            href="//bszs.conac.cn/sitename?method=show&amp;id=8974DF78B943341DE05310291AACFBB6"
            target="_blank"
            ><img
              id="imgConac"
              vspace="0"
              hspace="0"
              border="0"
              src="//dcs.conac.cn/image/red_error.png"
              data-bd-imgshare-binded="1" /></a
        ></span>
      </p>
      <p
        style="width: 440px;
        padding-top: 14px;
        position: absolute;
        right: 0;
        top: 0px;"
      >
          <sapn
            style="
              width: 91px;
              height: 91px;
              float: left;
              padding-right: 18px;
              text-align: center;
              display: block;
            "
          >
            <img
              src="http://192.168.5.231:10010/api/media/convey/download/6964b0838468ba7ffa3f4f62f904f9d3/PhoneImageTran?appName=yongqiaorm"
              style="width: 91px; height: 91px"
            /><br />宿州埇桥发布
          </sapn>
          <span
            style="
              width: 91px;
              height: 91px;
              float: left;
              padding-right: 18px;
              text-align: center;display: block;
            "
          >
            <img
              src="http://192.168.5.231:10010/api/media/convey/download/a4108563a611f9e747359af9ba00faac/PhoneImageTran?appName=yongqiaorm"
              style="width: 91px; height: 91px"
            /><br />埇桥教育
          </span>
          <span
            style="
              width: 91px;
              height: 91px;
              float: left;
              padding-right: 18px;
              text-align: center;display: block;
            "
          >
            <img
              src="http://192.168.5.231:10010/api/media/convey/download/55d4abbc96d66c5d7c039beedd49cf0f/OriginalFormat?appName=yongqiaorm"
              style="width: 91px; height: 91px"
            /><br />埇桥文明
          </span>
          <span
            style="
              width: 91px;
              height: 91px;
              float: left;
              padding-right: 18px;
              text-align: center;display: block;
            "
          >
            <img
              src="http://192.168.5.231:10010/api/media/convey/download/92eab2d0e838760d0e42a5c44e57a495/ComputerImageTran?appName=yongqiaorm"
              style="width: 91px; height: 91px"
            /><br />埇桥纪检
          </span>
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
  created() {
    // console.log('我的页脚数据')
  },
  computed: {
    webInfor() {
      return this.$store.state.$webInfor
    }
  }
}
</script>
 <style  lang="scss" scoped>
.footWeb {
  width: 100%;
  background: #E60011;
  background-size: 100% 100%;
  min-width: $primaryWidth;
  // padding: 15px 0px;
  margin: 0px auto;
  color: #fff;

  .footer {
    clear: both;
    width: 1100px;
    height: 130px;
    margin: 0 auto;
    color: #fff;
    font-size: 12px;
    box-sizing: border-box;
    position: relative;
  }
}
</style>
