<template>
    <div class="recommend" :style="{ width: width + 'px' }" v-if="!(nodata && comDs.length==0)" :class="appName==='yongqiaorm'?'recommend1':''">
      <el-carousel v-if="comDs.length>0" class="lunbobox" height="100%" arrow = "always" :autoplay="true" trigger="click" indicator-position="none">
        <el-carousel-item v-for="(item,index) in comDs" :key="index+'hh'">
          <div class="myitemsCon" @click="todetailsList(item)">
            <img
              class="imagescon"
              v-if="item.cover&&JSON.parse(item.cover).length"
              :src="JSON.parse(item.cover)[0]|uploadFilters"
              :title="appName==='yongqiaorm'?item.mainTitle:''"
              alt
            />
            <img class="imagescon" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
            <div  :title="item.mainTitle" class="tuitit boxs oneline" :class="titleType" v-if="appName==='yongqiaorm'">{{item.mainTitle}}</div>
            <el-tooltip class="item" effect="dark" :content="item.mainTitle" placement="bottom" v-if="appName!=='yongqiaorm'">
              <div class="tuitit boxs oneline" :class="titleType">{{item.mainTitle}}</div>
            </el-tooltip>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-else class="" style="width:100%;height:100%">
        <div class="myitemsCon">
            <img class="imagescon" :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
          </div>
      </div>
    </div>
  </template>

<script>
import props from './props.js'
import home from '@/api/index.js'
import { toDetails } from '../../utils/toDetail'
export default {
  name: 'reComment',
  mixins: [props],
  data() {
    return {
      appName: this.$store.state.$config.appName,
      comDs: []

    }
  },
  created() {
    if (this.specialId) {
      this.getSpecial('HD')
    } else {
      this.Headlines()
    }
    // this.Headlines()
  },
  methods: {
    todetailsList(item) {
      toDetails(item)
    },
    // 轮播图
    Headlines() {
      let attrSignList = ['HD']
      let url = 'getAppOperationGetColumnContribution'
      if (this.appName === 'yongqiaorm') {
        attrSignList = ['TJ', 'HD']
        url = 'getContributionByColumnAttr'
        home[url]({
          attrSignList: attrSignList, // 幻灯
          rows: 5,
          page: 1,
          columnSigns: []
        }, { appName: this.$store.state.$config.appName })
          .then((res) => {
            if (res.success) {
              this.comDs = res.data.items
              // res.data.items.forEach((i) => {
              //   if (JSON.parse(i.cover).length) {
              //     this.urls.push(i);
              //   }
              // });
            }
          })
      } else {
        home[url]({
          appName: this.$store.state.$config.appName,
          attrSignList: attrSignList, // 幻灯
          rows: 5,
          page: 1
        })
          .then((res) => {
            if (res.success) {
              console.log('这里是我的幻灯数据', res)
              this.comDs = res.data.items
              // res.data.items.forEach((i) => {
              //   if (JSON.parse(i.cover).length) {
              //     this.urls.push(i);
              //   }
              // });
            }
          })
      }
    },
    // 获取专题的幻灯文章
    getSpecial(sign) {
      home
        .selectTopicAttr({
          attrSignList: [sign], // 幻灯
          page: 1,
          pageSize: 6,
          topicId: this.specialId
        })
        .then((res) => {
          if (res.success) {
            this.comDs = res.data.items
          }
        })
    }
  }
}
</script>
  <style lang="scss" scoped>

  .recommend {
    width: 60%;
    height: 100%;
    .lunbobox {
      height: 100%;
    }
    .myitemsCon {
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;
      .imagescon {
        width: 100%;
        height: 100%;
        object-fit:cover;
      }
      .tuitit {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 46px;
        background: rgba(0, 0, 0, 0.45);
        color: #fff;
       // padding-left: 20px;
       // padding-right: 175px;
        line-height: 46px;
        font-size: 20px;
        text-align: center;
      }
      .shadeBo {
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      }
    }
    ::v-deep .el-carousel__indicators--horizontal {
      right: 20px;
      left: auto;
      transform: translateX(0%);
    }
    ::v-deep .el-carousel__arrow {
        top:95%;
        font-size:35px;
        border-radius:0;
        background-color: #80000000;

    }
    ::v-deep .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      opacity: 1;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background-color: #d80c0a;
    }
  }
  .recommend1{
    width:580px;
    height: 100%;
  }

  </style>
