<template>
  <div class="footWeb">
    <div class="bottom">
      <div v-html="webInfor && webInfor.WEB_FOOTER_INFORMSTION"></div>
    </div>

    <!-- <div
        style="width: 1150px;margin: 10px auto 0 auto;height: 141px;display:flex;align-items:center"
      >
        <a href="https://www.12377.cn/" style="display: block;float: left;margin-right:14px">
          <img src="https://first-common.bj.bcebos.com/common/jixisydw.png" />
        </a>
        <img src="https://first-common.bj.bcebos.com/common/jixinu.png" style="float:left" />
        <a href="https://www.12377.cn/" style="display: block;float: left;margin-left: 21px">
          <img src="https://first-common.bj.bcebos.com/common/jixijbzx.png" />
        </a>
        <div style="width: 626px;font-size: 13px;margin-left:132px;line-height: 27px;">
          <p style="text-align: center;">主办：绩溪县融媒体中心 地址：绩溪县良安路曹家井39号</p>
          <p style="text-align: center;">
            <a
              target="_blank"
              style="color:#333;"
              href="https://beian.miit.gov.cn/"
            >ICP备案： 皖ICP备16021462号-2</a> 涉网络暴力有害信息举报专区 “互联网算法推荐”专项举报 互联网违法和 不良信息暨涉未成年人举报电话：0563-8152905 举报邮箱：jixidst@163.com
          </p>
        </div>
      </div>-->
  </div>
</template>

<script>
export default {
  created() {
    // console.log('我的页脚数据')
  },
  computed: {
    webInfor() {
      return this.$store.state.$webInfor
    }
  }
}
</script>

  <style  lang="scss" scoped>
.footWeb {
  width: 100%;
  background-image: url("../../assets/yongqiaowm/footbg.gif");
  background-size: 100% 100%;
  min-width: $primaryWidth;
  // padding: 15px 0px;
  margin: 0px auto;
  color: #fff;
  .bottom {
    width: 1002px;
    color: #fff;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0 0 0 ;
    font-size: 15px;
    text-align: center; line-height: 25px;
  }
}
</style>
