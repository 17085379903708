<template>
  <div
    v-if="show"
    @mouseover="mouseover"
    @mouseout="mouseout"
    class="box"
    :style="{'top':top + 'px','left':left + 'px'}"
  >
    <img
      class="image"
      :src="imageData.advertisementImage | uploadFilters"
      @click="goToDetail(imageData)"
    />
    <span @click="close">关闭</span>
  </div>
</template>

<script>
import request from '@/api/index.js'
import { newNavToPage } from '@/utils/toDetail'
export default {
  name: 'MoveWindow',
  data() {
    return {
      show: false, // 是否展现飘窗
      stepX: 1, // 水平方向的步长
      stepY: 1, // 垂直方向的步长
      timer: null, // 定时器
      maxTop: 0, // 最大的 top 值
      maxLeft: 0, // 最大的 left 值
      top: 0,
      left: 0,
      imageData: {}
    }
  },
  created() {
    this.getAdverData()
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    // dom 销毁前清除定时器
    clearInterval(this.timer)
  },
  methods: {
    // 点击广告跳转去其他页面
    goToDetail(imageData) {
      newNavToPage(imageData)
    },
    // 广告的接口
    async getAdverData() {
      const res = await request
        .getAppOperationGetAdvListBySign({
          sign: 'WZPC',
          appName: this.configWeb.appName
        })
      if (res.success && res.data && res.data.length) {
        console.log(res.data[0], '>>跑的广告')
        this.imageData = res.data[0]
        this.show = true
      }
    },
    // 初始化飘窗规则
    init() {
      // 设置最大的top和left值：根元素可视区域宽高 - 飘窗的宽高 - 边距
      this.maxTop = document.documentElement.clientHeight - 202 - 20
      this.maxLeft = document.documentElement.clientWidth - 310 - 20

      // 设置 top 和 left 的初始值
      this.top = 0
      this.left = 0

      // 创建定时器前清除定时器，避免类似在 onresize 中调用 init() 时，产生多个定时器
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.move()
      }, 20)

      this.onresize()
    },
    // 移动函数
    move() {
      if (this.top >= this.maxTop || this.top < 0) {
        this.stepY = -this.stepY
      }
      if (this.left >= this.maxLeft || this.left < 0) {
        this.stepX = -this.stepX
      }

      this.top += this.stepY
      this.left += this.stepX
    },
    // 鼠标悬浮在飘窗时停止移动
    mouseover() {
      clearInterval(this.timer)
    },
    // 鼠标离开飘窗时恢复移动
    mouseout() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.move()
      }, 20)
    },
    // 关闭飘窗
    close() {
      clearInterval(this.timer)
      this.show = false
    },
    // 窗口大小调整时重置飘窗规则
    onresize() {
      const that = this
      window.onresize = function () {
        that.init()
      }
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  }
}
</script>

  <style lang="scss" scoped>
.box {
  width: 310px;
  border-radius: 5px;
  position: fixed;
  text-align: left;
  color: #ffffff;
  top: 0;
  left: 0;
  z-index: 1000;

  .image {
    width: 310px;
    height: 202px;
    overflow: hidden;
    object-fit: fill;
    border-radius: 5px;
  }
  span {
    display: block;
    height: 30px;
    width: 45px;
    color: #333;
    cursor: pointer;
  }
}
</style>
