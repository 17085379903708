<template>
    <div class="home" :class="{ huibaiSty: huibaiyemianData }">
        <div class="sideLeft">
        <img
          class="erweiBox"
          @click="adverToPage(item)"
          v-for="(item, index) in lefter"
          :key="index"
          :src="item.advertisementImage | uploadFilters"
          alt
        />
      </div>
      <headWeb naveType="indexBack" :bannersign="$Global.headWeb.bannersign" :sign="$Global.headWeb.sign" :adver="$Global.headWeb.adver"  />
      <div class="MainContent">
        <!--头条新闻 -->
       <touTiao></touTiao>
      <!--轮播图+右侧新闻 -->
      <div class="tuixin">
            <recommendBox titleType="shadeBo" :width="855" />
            <div class="right">
              <div class="newsListcon">
                <bigTitlefengtai :mytitles="allCon.tuijian" >
                  <div
                    v-for="(item, index) in allCon.tuijian"
                    :slot="[item.id]"
                    :key="index"
                  >
                    <indexNewsList
                      v-for="(sonit, sonin) in item.datas"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#2a2a2a"
                      singColor="#d2d2d2"
                      height="45px"
                       class="indexNewsList"
                      :signImg="signImg"
                    />
                  </div>
                </bigTitlefengtai>
              </div>
            </div>
          </div>
          <!-- 江淮暖新闻，乡镇部门，短视频---->
          <div class="listBox">
            <div class="listItem">
                <bigTitle :mytitles="allCon.JiangHuaiNuanXinWen" lineType="smallline" fontSize="23px">
                  <div
                    v-for="(item, index) in allCon.JiangHuaiNuanXinWen"
                    :slot="[item.id]"
                    :key="index"
                  >
                  <template v-if="item.datas&&item.datas.length">
                    <div class="JiangHuaiNuanXinWen" @click="todetailsList(item.datas[0])">
                      <el-image
                     class="thu"
                    v-if="item.datas[0].cover&&JSON.parse(item.datas[0].cover).length"
                    :src="JSON.parse(item.datas[0].cover)[0]|uploadFilters"
                    alt="" fit="cover" />
                    <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
                    <div class="title2 ">{{item.datas[0].mainTitle}}</div>
                  </div>
                    <NewsList
                      v-for="(sonit, sonin) in item.datas.slice(1)"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#40403F"
                      singColor="#d2d2d2"
                      fontSize="17px"
                      height="45px"
                      type="fengtai"
                    />
                  </template>
                  </div>
                </bigTitle>
              </div>
                <div class="listItem">
                <bigTitle :mytitles="allCon.XiangZhenBuMen" lineType="smallline" fontSize="23px">
                  <div
                    v-for="(item, index) in allCon.XiangZhenBuMen"
                    :slot="[item.id]"
                    :key="index"
                  >
                    <NewsList
                      v-for="(sonit, sonin) in item.datas"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#40403F"
                      singColor="#d2d2d2"
                      height="45px"
                       fontSize="17px"
                       type="fengtai"
                    />
                  </div>
                </bigTitle>
              </div>
              <div class="listItem">
                <bigTitle :mytitles="allCon.DuanShiPin1" lineType="smallline" fontSize="23px">
                  <div
                    v-for="(item, index) in allCon.DuanShiPin1"
                    :slot="[item.id]"
                    :key="index"
                  >
                  <template v-if="item.datas&&item.datas.length">
                  <div class="DuanShiPin1">
                    <div class="itemT" v-for="(sonit, sonin) in item.datas.slice(0,2)"
                      :key="sonin" @click="todetailsList(sonit)">
                      <el-image
                      class="thu"
                      v-if="sonit.cover&&JSON.parse(sonit.cover).length"
                      :src="JSON.parse(sonit.cover)[0]|uploadFilters"
                      alt="" fit="cover" />
                      <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
                      <div class="title2 ">{{sonit.mainTitle}}</div>
                    </div>
                  </div>
                    <NewsList
                      v-for="(sonit, sonin) in item.datas.slice(2)"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#40403F"
                      singColor="#d2d2d2"
                      height="45px"
                      type="fengtai"
                      fontSize="17px"
                     :signImg="signImg"
                    />
                    </template>
                  </div>
                </bigTitle>
            </div>
            </div>
            <!---专题专栏-->
            <div class="listbox1" >
              <div class="zhuantiLeft" v-if="allCon.ZhuanTiZhuanLan.length">
               <zhuanTi :mytitles="allCon.ZhuanTiZhuanLan" />
              </div>
              <div class="zhuantiRight">
                <carouselSign sign="BZ" height="240px" direction ="vertical"></carouselSign>
              </div>
            </div>
                <!---广告-->
            <div class="moreAdvider1">
              <div class="adimg3">
              <carouselSign sign="WZZBGG1" height="86px" direction ="vertical"></carouselSign>
              </div>
              <div class="adimg3">
              <carouselSign sign="WZZBGG2" height="86px" direction ="vertical"></carouselSign>
              </div>
              <div class="adimg3">
              <carouselSign sign="WZZBGG3" height="86px" direction ="vertical"></carouselSign>
              </div>
            </div>
        <!-- 县外媒体看丰台,新闻时评,公示公告---->
        <div class="listBox">
          <div class="listItem">
                <bigTitle :mytitles="allCon.XWMTKFT1" lineType="smallline" fontSize="23px">
                  <div
                    v-for="(item, index) in allCon.XWMTKFT1"
                    :slot="[item.id]"
                    :key="index"
                  >
                    <NewsList
                      v-for="(sonit, sonin) in item.datas"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#40403F"
                      singColor="#d2d2d2"
                      height="45px"
                      fontSize="17px"
                      type="fengtai"
                    />
                  </div>
                </bigTitle>
              </div>
              <div class="listItem">
                <bigTitle :mytitles="allCon.XWSP1" lineType="smallline" fontSize="23px">
                  <div
                    v-for="(item, index) in allCon.XWSP1"
                    :slot="[item.id]"
                    :key="index"
                  >
                    <NewsList
                      v-for="(sonit, sonin) in item.datas"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#40403F"
                      singColor="#d2d2d2"
                      height="45px"
                      fontSize="17px"
                      type="fengtai"
                    />
                  </div>
                </bigTitle>
              </div>
              <div class="listItem">
                <bigTitle :mytitles="allCon.GSGG1" lineType="smallline" fontSize="23px">
                  <div
                    v-for="(item, index) in allCon.GSGG1"
                    :slot="[item.id]"
                    :key="index"
                  >
                    <NewsList
                      v-for="(sonit, sonin) in item.datas"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#40403F"
                      singColor="#d2d2d2"
                      height="45px"
                      fontSize="17px"
                      type="fengtai"
                    />
                  </div>
                </bigTitle>
              </div>
         </div>
          <!--创建无烟单位，营造和谐、文明、健康的工作环境 -->
         <div class="bigSwiperBox2">
           <carouselSign sign="LGG2" height="100px" direction ="vertical"></carouselSign>
        </div>
        <!--图看凤台-->
        <div class="listBox12">
          <bigTitle :mytitles="allCon.TuKanFengTai" lineType="smallline" fontSize="23px">
                  <div
                    v-for="(item, index) in allCon.TuKanFengTai"
                    :key="index"
                     :slot="[item.id]"
                  >
                  <div v-if="item.datas&&item.datas.length" class="TuKanFengTai">
                  <div class="leftItem"  @click="todetailsList(item.datas[0])">
                    <el-image
                     class="thu"
                    v-if="item.datas[0].cover&&JSON.parse(item.datas[0].cover).length"
                    :src="JSON.parse(item.datas[0].cover)[0]|uploadFilters"
                    alt="" fit="cover" />
                    <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
                    <div class="tuitit oneline ">{{item.datas[0].mainTitle}}</div>
                  </div>
                  <div class="rightItem" >
                    <div class="item" v-for="(sonit, sonin) in item.datas.slice(1)"
                      :key="sonin"
                       @click="todetailsList(sonit)"
                      >
                      <el-image
                     class="thu"
                    v-if="sonit.cover&&JSON.parse(sonit.cover).length"
                    :src="JSON.parse(sonit.cover)[0]|uploadFilters"
                    alt=" " fit="cover"/>
                    <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
                    <div class="tuitit oneline ">{{sonit.mainTitle}}</div>
                    </div>
                  </div>
                  </div>
                  </div>
                </bigTitle>
        </div>
         <!--底部广告图 社会民生-->
         <div class="listBox12">
          <div class="listItem1">
            <bigTitle :mytitles="allCon.KePuZhiChuang" lineType="smallline" fontSize="23px">
              <div
                    v-for="(item, index) in allCon.KePuZhiChuang"
                    :key="index"
                     :slot="[item.id]"
                     class="twoBox"
                  >
                  <template v-if="item.datas&&item.datas.length">
                    <div class="listItem">
                      <div class="item"
                       @click="todetailsList(item.datas[0])"
                      >
                      <el-image
                     class="thu"
                    v-if="item.datas[0].cover&&JSON.parse(item.datas[0].cover).length"
                    :src="JSON.parse(item.datas[0].cover)[0]|uploadFilters"
                    alt="" fit="cover" />
                    <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
                    <div class="tuitit  oneline ">{{item.datas[0].mainTitle}}</div>
                    </div>
                    <NewsList
                          v-for="(sonit, sonin) in item.datas.slice(1,5)"
                          :items="sonit"
                          :key="sonin"
                          fontColor="#40403F"
                          singColor="#d2d2d2"
                          height="50px"
                          fontSize="17px"
                          type="fengtai"
                        />
                  </div>
                  <div class="listItem"     v-if="item.datas.length>5">
                    <div class="item"
                       @click="todetailsList(item.datas[5])"
                      >
                      <el-image
                       class="thu"
                      v-if="item.datas[5].cover&&JSON.parse(item.datas[5].cover).length"
                      :src="JSON.parse(item.datas[5].cover)[0]|uploadFilters"
                      alt="" fit="cover" />
                    <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
                    <div class="tuitit   oneline ">{{item.datas[5].mainTitle}}</div>
                    </div>
                    <NewsList
                          v-for="(sonit, sonin) in item.datas.slice(6,10)"
                          :items="sonit"
                          :key="sonin"
                          fontColor="#40403F"
                          singColor="#d2d2d2"
                          height="50px"
                          fontSize="17px"
                          type="fengtai"
                        />
                  </div>
                </template>
                  </div>
              </bigTitle>
          </div>
          <div class="listItem2">
            <div class="myboxdata">
                <div
                  class="titles smallline"
                  @click="toOtherData(allCon.SheHuiMinSheng[0])"
                  v-if="allCon.SheHuiMinSheng"
                >
                  <span>{{allCon.SheHuiMinSheng[0]?.navigationName?allCon.SheHuiMinSheng[0].navigationName:''}}</span>
                </div>
              </div>
                  <div
                    v-for="(item, index) in allCon.SheHuiMinSheng"
                    :key="index"
                  >
                  <template v-if="item.datas&&item.datas.length">
                  <div class="DuanShiPin1">
                    <div class="itemT" v-for="(sonit, sonin) in item.datas.slice(0,2)"
                      :key="sonin" @click="todetailsList(sonit)">
                      <el-image
                      class="thu"
                      v-if="sonit.cover&&JSON.parse(sonit.cover).length"
                      :src="JSON.parse(sonit.cover)[0]|uploadFilters"
                      alt="" fit="cover" />
                      <img class="thu" v-else :src="require(`@/assets/defaultImg/${appName}.png`)" alt />
                      <div class="title2 ">{{sonit.mainTitle}}</div>
                    </div>
                  </div>
                    <NewsList
                      v-for="(sonit, sonin) in item.datas.slice(2)"
                      :items="sonit"
                      :key="sonin"
                      fontColor="#40403F"
                      singColor="#d2d2d2"
                      height="50px"
                      type="fengtai"
                      fontSize="17px"
                    />
                    </template>
                  </div>
          </div>
         </div>

        <!--底部广告图 -->
        <div  class="moreAdvider2">
          <div class="adimg2">
            <carouselSign sign="WZDBGG1" height="86px" direction ="vertical"></carouselSign>
          </div>
          <div class="adimg2">
          <carouselSign sign="WZDBGG2" height="86px" direction ="vertical"></carouselSign>
          </div>
      </div>
      <div class="moreAdvider3">
        <div class="adimg3">
        <carouselSign sign="WZDBGG3" height="86px" direction ="vertical"></carouselSign>
        </div>
        <div class="adimg3">
        <carouselSign sign="WZDBGG4" height="86px" direction ="vertical"></carouselSign>
        </div>
        <div class="adimg3">
        <carouselSign sign="WZDBGG5" height="86px" direction ="vertical"></carouselSign>
        </div>
      </div>
      </div>
    <footWeb></footWeb>
       <!-- 飘窗跑的飘窗 -->
     <bayWindow></bayWindow>
    </div>
  </template>

<script>
import footWeb from '@/components/footWeb/footWeb'
import headWeb from '@/components/headWeb/headWeb'
import home from '@/api/index.js'
import { adverTopage, jumpTopage, toDetails } from '@/utils/toDetail'
import bayWindow from './bayWindow/bayWindow'
import touTiao from '@/components/touTiao/fengtaiTouTiao'
import recommendBox from '@/components/recommend/fengtai'
import bigTitlefengtai from '@/components/indexTitles/bigTitleFengTai'
import indexNewsList from '@/components/indexNewsList/fengtaiNewsList'
import NewsList from '@/components/indexNewsList/indexNewsList'
import carouselSign from '@/components/carouselSign/carouselSign.vue'
import bigTitle from '@/components/indexTitles/bigTitle'
import zhuanTi from '@/components/zhuanTi/fengtaiZhuanTi'
export default {
  name: 'HomeView',
  components: {
    footWeb,
    headWeb,
    bayWindow,
    touTiao,
    recommendBox,
    bigTitlefengtai,
    indexNewsList,
    NewsList,
    bigTitle,
    carouselSign,
    zhuanTi
  },
  data() {
    return {
      huibaiyemianData: false,
      adverData: [], // 广告数据
      headData: [], // 头条数据
      allCon: {
        tuijian: [], // 推荐的数据
        JiangHuaiNuanXinWen: [],
        XiangZhenBuMen: [],
        DuanShiPin1: [],
        XWMTKFT1: [],
        XWSP1: [],
        GSGG1: [],
        TuKanFengTai: [],
        KePuZhiChuang: [],
        SheHuiMinSheng: [],
        ZhuanTiZhuanLan: []
      },
      bottomMin: false,
      backheadcon: true,
      erweima: [],
      lefter: [],
      toTopBut: false,
      appName: this.$store.state.$config.appName,
      signImg: require('@/assets/fengtaiapp/videoIcon.png')

    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  },
  created() {
    const that = this
    window.addEventListener('scroll', function (e) {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 600) {
        that.toTopBut = true
      } else {
        that.toTopBut = false
      }
    })

    this.$nextTick(() => {
      this.mycontentNav()
      this.huibaiYemian() // 页面灰白
      this.sideTipscon() // 二维码下载
    })
  },
  methods: {

    // 跳转到其他页面
    toOtherData(item) {
      if (item.columnId && item.wenming) { // 文明实践列表跳转
        this.$router.push({
          path: '/newsListWenMing',
          query: {
            columnId: item.columnId
          }
        })
      } else if (item.otherLink) {
        window.open(item.otherLink)
      } else if (item.dataLink !== '') {
        let colum = ''
        if (item.columnSign) {
          if (item.dataLink.indexOf('?') === -1) {
            colum = '?column=' + item.columnSign
          } else {
            colum = '&column=' + item.columnSign
          }
        }
        if (this.$route.fullPath !== item.dataLink) {
          const { href } = this.$router.resolve({
            path: item.dataLink + colum
          })
          window.open(href)
        }
      }
    },
    toTopClick(i) {
      let timer
      // 参数i表示间隔的幅度大小，以此来控制速度
      document.documentElement.scrollTop -= i
      if (document.documentElement.scrollTop > 0) {
        timer = setTimeout(() => this.toTopClick(i))
      } else {
        if (timer) {
          clearTimeout(timer)
        }
      }
    },

    toPage(item) {
      jumpTopage(item)
    },
    adverToPage(item) {
      if (item.advertisementSign === 'WZPC') {
        const encrypt = this.fstEncrypt(
          item.advertisementIink,
          this.$Global.$aesKey,
          this.$Global.$aesIv
        )
        const { href } = this.$router.resolve({
          path: '/yongqiaowmDeatil?id=' + encrypt
        })

        window.open(href)
      } else {
        adverTopage(item)
      }
    },
    // 父栏目查询所以子栏目文章也可以指定查询子栏目
    HeadlinesList1(arrList, number, flage, listTitle, name, sta) {
      home
        .getContributionByColumnAndTitle({
          appName: this.configWeb.appName,
          attrSignList: arrList,
          rows: number,
          isShowInfo: flage,
          columnSign: listTitle
        })
        .then((res) => {
          if (res.success) {
            // console.log('我的头条数据', name, res.data.items, sta)
            if (sta) {
              this.$set(name, 'datas', res.data.items)
            } else {
              this[name] = res.data.items
              // console.log('我的头条数据', res, this.headData)
            }
          }
        })
    },
    // 头条新闻
    HeadlinesList(arrList, number, flage, listTitle, name, sta) {
      home
        .getAppOperationGetColumnContribution({
          appName: this.configWeb.appName,
          attrSignList: arrList,
          rows: number,
          isShowInfo: flage,
          columnSign: listTitle
        })
        .then((res) => {
          if (res.success) {
            // console.log('我的头条数据', name, res.data.items, sta)
            if (sta) {
              this.$set(name, 'datas', res.data.items)
            } else {
              this[name] = res.data.items
              // console.log('我的头条数据', res, this.headData)
            }
          }
        })
    },
    huibaiYemian() {
      this.adverDataLink('YMHB', 'yemian')
    },
    // 侧边栏二维码
    sideTipscon() {
      this.adverDataLink('ZSEWM', 'erweima')
    },
    // 左侧边栏
    sideTipLeft() {
      this.adverDataLink('WZCBGG', 'sideLeft')
    },
    // 广告的接口
    adverDataLink(sign, its) {
      home
        .getAppOperationGetAdvListBySign({
          sign,
          appName: this.configWeb.appName
        })
        .then((res) => {
          if (res.success && res.data && res.data.length) {
            if (its === 'erweima') {
              this.erweima = res.data
            } else if (its === 'sideLeft') {
              this.lefter = res.data
            } else if (its === 'yemian') {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].advertisementSign === 'WYHB') {
                  this.huibaiyemianData = true
                  break
                }
              }
            } else if (its) {
              this.$set(its, 'datas', res.data)
            } else {
              this.adverData = res.data
            }
          }
        })
    },
    // 导航接口
    mycontentNav() {
      // 导航
      home
        .getAppOperationGetNavListBySign({
          sign: 'WZSYNR',
          appName: this.configWeb.appName
        })
        .then(async (res) => {
          if (res.success) {
            res.data[0].info.map((it) => {
              if (
                it.navigationSign === 'FengTaiYaoWen'
              ) {
                this.HeadlinesList1([], 6, true, it.columnSign, it, true)
                this.allCon.tuijian.push(it)
              } else if (it.navigationSign === 'JiangHuaiNuanXinWen') {
                this.HeadlinesList1([], 6, true, it.columnSign, it, true)
                this.allCon.JiangHuaiNuanXinWen.push(it)
              } else if (it.navigationSign === 'XiangZhenBuMen') {
                this.HeadlinesList1([], 7, true, it.columnSign, it, true)
                this.allCon.XiangZhenBuMen.push(it)
              } else if (it.navigationSign === 'DuanShiPin1') {
                this.HeadlinesList1([], 5, true, it.columnSign, it, true)
                this.allCon.DuanShiPin1.push(it)
              } else if (it.navigationSign === 'XWMTKFT1') {
                this.HeadlinesList1([], 5, true, it.columnSign, it, true)
                this.allCon.XWMTKFT1.push(it)
              } else if (it.navigationSign === 'XWSP1') {
                this.HeadlinesList1([], 5, true, it.columnSign, it, true)
                this.allCon.XWSP1.push(it)
              } else if (it.navigationSign === 'GSGG1') {
                this.HeadlinesList1([], 5, true, it.columnSign, it, true)
                this.allCon.GSGG1.push(it)
              } else if (it.navigationSign === 'TuKanFengTai') {
                this.HeadlinesList1([], 5, true, it.columnSign, it, true)
                this.allCon.TuKanFengTai.push(it)
              } else if (it.navigationSign === 'KePuZhiChuang') {
                this.HeadlinesList1([], 10, true, it.columnSign, it, true)
                this.allCon.KePuZhiChuang.push(it)
              } else if (it.navigationSign === 'SheHuiMinSheng') {
                this.HeadlinesList1([], 7, true, it.columnSign, it, true)
                this.allCon.SheHuiMinSheng.push(it)
              } else if (it.navigationSign === 'ZhuanTiZhuanLan') {
                this.HeadlinesList1([], 21, true, it.columnSign, it, true)
                this.allCon.ZhuanTiZhuanLan.push(it)
              }

              return it
            })
          }
        })
      // console.log(this.allCon, '===this.allCon')
    },
    //
    todetailsList(items) {
      toDetails(items)
    }

  }
}
</script>

  <style lang="scss" scoped>

  .MainContent {
    width: $primaryWidth;
     margin: 0 auto;
    position: relative;
    background: #fff;
    z-index: 99;
    .moreAdvider {
    background-color: #fff;
    width: $primaryWidth;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    .myname {
        cursor: pointer;
        width: 100%;
        height: auto;
        &:not(:first-child) {
        margin-top: 7px;
        }
    }
    }
  }

  .moreAdvider2{
    width: $primaryWidth;
    display: flex;
    margin:17px 0;
    align-items: center;
    height: 86px;
    .adimg2:first-child {
        width: 855px;
        cursor: pointer;
    }
    .adimg2:last-child {
        margin-left:15px;
        width: 425px;
        cursor: pointer;
    }
  }
  .moreAdvider3{
    margin-bottom:60px;
    width: $primaryWidth;
    display: flex;
    .adimg3{
        width: 425px;
        height: 86px;
        margin-left:15px;
        cursor: pointer;
    }
    .adimg3:first-child {
        margin-left:0px;
    }
  }
  .moreAdvider1{
    margin-top:20px;
    width: $primaryWidth;
    display: flex;
    .adimg3{
        width: 420px;
        height: 86px;
        margin-left:15px;
        cursor: pointer;
    }
    .adimg3:first-child {
        margin-left:0px;
    }
  }
  .tuixin {
  width: $primaryWidth;
   height: 480px;
  display: flex;
  justify-content: space-between;
  margin-top:19px;
  .right {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 855px);
    overflow: hidden;
    background: #F5F5F7;
    .newsListcon {
     padding:17px;
      box-sizing: border-box;
    }
  }
}

.listBox{
  margin-top:30px;
   display: flex;
   width: 100%;
   justify-content: space-between;
   .listItem{
    width: 414px;

   }
}
.listBox12{
  margin-top:20px;
   display: flex;
   width: 100%;
   justify-content: space-between;
}
//不能删除
.huibaiSty {
  filter: grayscale(100%);
}
.sideLeft {
  position: fixed;
  left: calc((100% - 1500px) / 2);
  top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  .erweiBox {
    width: 120px;
    height: auto;
    cursor: pointer;
  }
}
.indexNewsList{
    border-bottom: 1px dashed #868686;
}
.indexNewsList:last-child{
    border-bottom: none;
}
.JiangHuaiNuanXinWen{
   display: flex;
   height: 92px;

   .thu{
    width: 162px;
    height: 92px;
    border-radius: 5px;
   }
   .title2{
    height: 60px;
    width: calc( 100% - 162px);
    font-size: 17px;
    color: #40403F;
    margin:15px  15px 15px 19px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    line-height: 28px;
    cursor: pointer;
   }
   .title2:hover{
    color: $primaryColor;
   }
}
.DuanShiPin1{
   display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
   .itemT{
    width: 190px;;
   }
  .thu{
    width: 190px;
    height: 108px;
   }
   .title2{
    font-size: 17px;
    color: #40403F;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    line-height: 28px;
    cursor: pointer;
   }
   .title2:hover{
    color: $primaryColor;
   }

}
.bigSwiperBox2{ margin-top:19px;   margin-bottom:0px;}
.TuKanFengTai{
   display: flex;
   height: 407px;
   width: 100%;
  .leftItem{
    width: 640px;
    position: relative;
    transition: all 0.5s;
    // &:hover {
    //       transform: scale(1.1, 1.1);
    //     }
    .thu{
      width: 640px;
      height: 407px;
      cursor: pointer;

      }
  }
  .rightItem{
    width: calc(100% - 640px);
    display: flex;
    flex-wrap: wrap;

    .item{
      position: relative;
      width: 320px;
      height: 200px;
      margin-bottom: 8px;
      margin-left: 8px;
      transition: all 0.5s;
      overflow: hidden;
      // &:hover {
      //     transform: scale(1.1, 1.1);
      //   }
      .thu{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

    }
  }
  .tuitit{
    position: absolute;
    bottom: 0px;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 18px;
  }
  .tuitit:hover{
    color: #fff;
 }
}

.listbox1{
  display: flex;
  margin-top:20px;
  .zhuantiLeft{
    width: 854px;
  }
  .zhuantiRight{
    width: 420px;
    height: 240px;
    margin-left: 20px;
  }
}
.listItem1{
      width: 854px;
    }
.listItem2{
  width: 417px;
  height: 502px;
  background: #F8F8F8;
  margin-left: 21px;
  padding: 13px;
  box-sizing: border-box;
  .thu{
    width: 190px;
    height: 108px;
   }

}
.tuitit{
  cursor: pointer;
}
.tuitit:hover{
    color: $primaryHoverColor;
 }
 .myboxdata {
    display: flex;
    width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  }
  .titles {
    height: 40px;
    line-height: 40px;
    font-size: 23px;
    position: relative;
    color: $primaryColor;
    font-weight: bold;
    cursor: pointer;

  }
  .twoBox{
     display: flex;
     justify-content: space-between;
     width: 100%;
     .listItem{
      width: 50%;
      .item{
      position: relative;
      width: 418px;
      height: 238px;
      margin-bottom: 8px;
      margin-left: 8px;
      .thu{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .tuitit{
        position: absolute;
        bottom: 0px;
        padding-left: 18px;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 10px;
      }
      .tuitit:hover{
        color: #fff;
     }
    }
     }
  }

  .tuitit{
        font-size: 17px;
        color: #FFFFFF;
        line-height: 28px;
        box-sizing: border-box;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
        width: 100%;
        cursor: pointer;
      }
  </style>
