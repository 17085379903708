// 推荐轮播图
export default {
  props: {
    mytitles: {
      require: true,
      default: () => {
        return []
      }
    }
  }
}
