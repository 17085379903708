import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/index.vue'// 郎溪
// import HomeView from '../views/jixiIndex.vue'// 绩溪
// import HomeView from '../views/xuanZhouIndex.vue' // 宣州
// import HomeView from '../views/lixinIndex.vue' // 利辛
// import HomeView from '../views/qingyang.vue' // 青阳
// import HomeView from '../views/yongqiaowm.vue' // 埇桥文明
// import HomeView from '../views/yongqiaoxw.vue' // 埇桥新闻
// import HomeView from '../views/dongzhiIndex.vue' // 指尖东至
//  import HomeView from '../views/xuanzhouwm.vue' // 宣州文明
import HomeView from '../views/fengtaiIndex.vue' // 凤台新闻
import config from '../config' // 页面的基本配置
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: config.webName
    }
  },
  { // 新闻列表
    path: '/newslist',
    name: 'newslist',
    component: () =>
      import(
        /* webpackChunkName: "newslist" */ '../views/newsList.vue'
      )
  },
  { // 新闻列表 绩溪
    path: '/newslistWenMing',
    name: 'newslistWenMing',
    component: () =>
      import(
        /* webpackChunkName: "newslist" */ '../views/newsListWenMing.vue'
      )
  },
  { // 新闻列表搜索 绩溪
    path: '/searchNewslist',
    name: 'searchNewslist',
    component: () =>
      import(
        /* webpackChunkName: "newslist" */ '../views/searchNewslist.vue'
      )
  },
  { // 三级新闻列表
    path: '/threeNewList',
    name: 'threeNewList',
    component: () =>
      import(
        /* webpackChunkName: "newslist" */ '../views/threeNewList.vue'
      )
  },
  { // 新闻详情
    path: '/newsDetail',
    name: 'newsDetail',
    component: () =>
      import(
        /* webpackChunkName: "newsDetail" */ '../views/newsDetail/newsDetail.vue'
      )
  }, { // 新闻详情
    path: '/specialColumn',
    name: 'specialColumn',
    component: () =>
      import(
        /* webpackChunkName: "specialColumn" */ '../views/specialColumn.vue'
      )

  },
  // 电视直播
  {
    path: '/tvLive',
    name: 'tvLive',
    component: () =>
      import(
        /* webpackChunkName: "tvLive" */'../views/tvLive/tvLive.vue'
      ),
    meta: {
      title: config.webName
    }
  },
  // 专题专栏
  {
    path: '/tvSpecial',
    name: 'tvSpecial',
    component: () =>
      import(/* webpackChunkName: "tvSpecial" */'../views/tvSpecial/tvSpecial.vue'),
    meta: {
      title: config.webName
    }
  },
  // 郎溪宣传片
  {
    path: '/video',
    name: 'video',
    component: () =>
      import(/* webpackChunkName: "lxVideo" */'../views/advertise/lxVideo.vue'),
    meta: {
      title: config.webName
    }
  },
  // 宣州文明实践
  {
    path: '/xuanzhouWenMing',
    name: 'xuanzhouWenMing',
    component: () =>
      import(/* webpackChunkName: "lxVideo" */'../views/xuanzhou/xuanzhouWenMing.vue'),
    meta: {
      title: config.webName
    }
  },
  {
    path: '/specialOne',
    name: 'specialOne',
    component: () =>
      import(/* webpackChunkName: "lxVideo" */'../views/xuanzhou/specialOne.vue'),
    meta: {
      title: config.webName
    }
  },
  {
    path: '/specialTwo',
    name: 'specialTwo',
    component: () =>
      import(/* webpackChunkName: "lxVideo" */'../views/xuanzhou/specialTwo.vue'),
    meta: {
      title: config.webName
    }
  },
  {
    path: '/specialThree',
    name: 'specialThree',
    component: () =>
      import(/* webpackChunkName: "lxVideo" */'../views/xuanzhou/specialThree.vue'),
    meta: {
      title: config.webName
    }
  },

  {
    path: '/yongqiaowmDeatil',
    name: 'yongqiaowmDeatil',
    component: () => import(/* webpackChunkName: "yongqiaowmDeatil" */ '../views/yongqiaowmDeatil.vue')
  },
  {
    path: '/yongqiaoxwMatrix',
    name: 'yongqiaoxwMatrix',
    component: () => import(/* webpackChunkName: "yongqiaoxwMatrix" */ '../views/yongqiaoxwMatrix.vue')
  },
  {
    path: '/newsListWMYongpiao',
    name: 'newsListWMYongpiao',
    component: () => import(/* webpackChunkName: "newsListWMYongpiao" */ '../views/newsListWMYongpiao.vue')
  },
  // 东至融媒
  {
    path: '/dongzhirongmei',
    name: 'dongzhirongmei',
    component: () =>
      import(/* webpackChunkName: "lxVideo" */'../views/dongzhirongmei.vue'),
    meta: {
      title: config.webName
    }
  },
  {
    path: '/hostList',
    name: 'hostList',
    component: () =>
      import(/* webpackChunkName: "lxVideo" */'../views/hostList.vue'),
    meta: {
      title: config.webName
    }

  }

]

const router = new VueRouter({
  mode: 'history', // hash宣州 其他区域history
  base: process.env.BASE_URL,
  routes
})
export default router
