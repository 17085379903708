// import Fingerprint2 from 'fingerprintjs2'
// import config from '../config'
// 获取浏览器指纹，生成浏览器唯一标识符
// export function gcontainEquipmentInfo () {
//   const grayHeader = config.appname + timeToString(new Date(), 'none')
//   setTimeout(() => {
//     Fingerprint2.get((components) => {
//       // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
//       const values = components.map((component) => component.value) // 配置的值的数组
//       const equipmentNumber = Fingerprint2.x64hash128(values.join(''), 31) // 生成浏览器指纹
//       // console.log(equipmentNumber + ">>指纹");
//       localStorage.setItem(
//         'equipmentInfo',
//         JSON.stringify({
//           equipmentNumber,
//           grayHeader
//         })
//       )
//     })
//   }, 500)
// }

export function timeToString (date, str) {
  const Y = date.getFullYear() + ''
  const M =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const m =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const s =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  if (str === 'start') {
    return Y + '-' + M + '-' + D + ' ' + '00:00:00'
  } else if (str === 'none') {
    return Y + M + D
  } else if (str === '日期') {
    return Y + '年' + M + '月' + D + '日'
  } else if (str === '时间') {
    return h + ':' + m
  } else {
    return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
  }
}
