<template>
    <div class="toutiaoBox">
        <div class="yq_toutiao"><img src="@/assets/fengtaiapp/toutiao.png" /></div>
        <div class="publicity" v-if="comDsTT.length>0">
            <div class="h1 oneline" @click="todetailsList(comDsTT[0])">{{ comDsTT[0].mainTitle }}</div>
                <div class="items ">
                <p   v-for="(item,index) in comDsTT.slice(1)" @click="todetailsList(item)" :key="index">
                    <span class="one ">{{item.mainTitle }}</span> <span class="line" v-if="index+1!==comDsTT.slice(1).length"></span></p>
            </div>
        </div>
    </div>
  </template>

<script>
import home from '@/api/index.js'
import { toDetails } from '../../utils/toDetail'
export default {
  components: {},
  props: {},
  data() {
    return {
      comDsTT: [
      ],
      appName: this.$store.state.$config.appName
    }
  },
  computed: {
    configWeb() {
      return this.$store.state.$config
    }
  },
  filters: {
    times(data) {
      if (data) {
        const one = data.split(' ')
        if (one.length) {
          const two = one[0].split('-')
          two.shift()
          return two.join('-')
        }
      }
    }
  },
  created() {},
  mounted() {
    this.HeadlinesTT()
  },
  methods: {

    toPage() {
      const { href } = this.$router.resolve({
        path: '/newslist' + '?column=pingyujinren'
      })
      window.open(href)
    },
    HeadlinesTT() {
      home
        .getAppOperationGetColumnContribution({
          appName: this.$store.state.$config.appName,
          attrSignList: ['TJ', 'TT'], // 幻灯
          rows: 4
        })
        .then((res) => {
          if (res.success) {
            this.comDsTT = res.data.items
          }
        })
    },
    // 图片跳转
    todetailsList(val) {
      toDetails(val)
    },
    toHostlist() {
      this.$router.push({ path: '/hostList' })
    }
  }
}
</script>

<style lang="scss" scoped>
.toutiaoBox{
    width: $primaryWidth;
    display: flex;

    box-sizing: border-box;
    margin-top:34px;
    margin-bottom:29px;
    .yq_toutiao{
        width: 60px;
        height:64px;
    }
    .publicity{
        width:  calc($primaryWidth - 60px);
        margin-left: 10px;
        box-sizing: border-box;
        .h1{
            color: #323332;
            font-size: 40px;
            font-weight: bold;
            width: 100%;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
        }
        .more{
            color: #c0281c;
            font-size: 15px;
            cursor: pointer;
        }
        .h1:hover,.more:hover{
           color:$primaryHoverColor;
        }

        .items{
            display: flex;
            padding-top: 20px;
            width:calc($primaryWidth - 100px) ;
            justify-content: center;
            overflow: hidden;
            p{
                display:flex;
                max-width: 33%;
                align-items:center;
                justify-content: center;
            }
          .one{
            font-size: 17px;
            color: #484848;
            text-align: center;
            padding-right: 10px;
            white-space: nowrap; //禁止换行
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            cursor: pointer;
          }
          .one:hover{
            color: #c0281c;
          }
        }
    }
}
.line{
    width:2px;
    height: 20px;
    background: #B8B8B8;
    margin:0 40px 0  20px;
    box-sizing:border-box;
    display:inline-block;
}

</style>
